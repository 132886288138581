const ICON_SIZE = '16px';

// These icons are copied from @frontend/components package
// it's not currently possible to extract the SVG data from the icons there
// and we don't want to add a dependency on @frontend/components or Chakra-ui
// within the RFQ package, so we're duplicating the icons here for now

export const CloseIcon = () => {
  return (
    <svg viewBox="0 0 14 14" width={ICON_SIZE} height={ICON_SIZE}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2867 12.25L1.75 2.71335L2.71335 1.75L12.25 11.2867L11.2867 12.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 2.71335L2.71335 12.25L1.75 11.2867L11.2867 1.75L12.25 2.71335Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const UploadDiskIcon = () => {
  return (
    <svg viewBox="0 0 14 14" width={ICON_SIZE} height={ICON_SIZE}>
      <path
        d="M2.33334 11.0833H11.6667V12.25H2.33334V11.0833Z"
        fill="currentColor"
      />
      <path
        d="M11.0833 6.41666H8.75V9.91666H5.25V6.41666H2.91666L7 1.75L11.0833 6.41666Z"
        fill="currentColor"
      />
    </svg>
  );
};
