import {
  getMaterialOptionsForCascaderForSupplierMaterials,
  StoreContext,
} from 'Store';
import { useContext } from 'react';
import styled from 'styled-components';

import Select from '../ui/Select';
import CascaderMaterialSelector from './CascaderMaterialSelector';
import { OTHER_MATERIAL } from './constants';

const MaterialSelect = styled(Select)`
  font-size: 14px;
  margin-right: 10px;
  width: 100%;
`;

const DEFAULT_OPTION = {
  value: null,
  label: <span style={{ color: '#747474' }}>Material</span>,
};

const ProcessMaterialSelector = ({ disabled, onChange, process, values }) => {
  const { globalState } = useContext(StoreContext);

  const { materialClasses, materialFamilies, materials } = globalState;

  const getProcessMaterialListOptions = () => {
    const materialOptions = process
      ? process.materials
          .map((material) => ({
            value: material.supplier_material.id,
            label: material.supplier_material.display_name,
            materialId: material.supplier_material.material.id,
          }))
          .sort((x, y) => x.label.localeCompare(y.label))
      : [];
    return [DEFAULT_OPTION].concat(materialOptions).concat([
      {
        value: OTHER_MATERIAL,
        label: 'Other (See Notes)',
      },
    ]);
  };

  const getProcessMaterialCascaderOptions = () => {
    const useSupplierMaterials = process
      ? process.materials.map((processMaterial) => {
          return {
            ...processMaterial.supplier_material,
            material_id: processMaterial.supplier_material.material.id,
          };
        })
      : [];
    return getMaterialOptionsForCascaderForSupplierMaterials(
      materialClasses,
      materialFamilies,
      materials,
      useSupplierMaterials,
      false,
    );
  };

  return process && !process.use_list_display_for_process_materials ? (
    <CascaderMaterialSelector
      disabled={disabled}
      materialId={values.material}
      materialOptions={getProcessMaterialCascaderOptions()}
      placeholder="Material"
      onChange={({ materialId, supplierMaterialId }) => {
        onChange({
          ...values,
          material: materialId,
          supplier_material: supplierMaterialId,
        });
      }}
      supplierMaterialId={values.supplier_material}
    />
  ) : (
    <MaterialSelect
      disabled={disabled}
      value={values.supplier_material}
      placeholder="Material"
      clearable={false}
      options={getProcessMaterialListOptions()}
      onChange={(option) =>
        onChange({
          ...values,
          supplier_material: option,
        })
      }
    />
  );
};

export default ProcessMaterialSelector;
