import { Field } from 'formik';
import { pathOr } from 'ramda';
import styled from 'styled-components';

import {
  COUNTRY_REGIONS,
  COUNTRIES,
  COUNTRY_POSTAL_CODE_REGEX,
} from '@frontend/addresses';

import Form from '../ui/Form';
import Select from '../ui/Select';
import Input from '../ui/TextInput';

const Wrapper = styled.div``;

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;

  .react-select__control {
    padding: 0.5rem 0 0.5rem 0;
  }
  .react-select__indicators {
    top: 0.8rem;
  }
`;

const ContactAddress = () => {
  return (
    <Wrapper>
      <Field name="address.address1">
        {({ field, form }) => (
          <Form.Field
            grow={1}
            label="Address 1"
            showLabel={!!field.value}
            error={
              form.touched.address?.[field.name.split('.')[1]] &&
              form.errors.address?.[field.name.split('.')[1]]
            }
          >
            <Input {...field} placeholder="Address 1" autoComplete="on" />
          </Form.Field>
        )}
      </Field>
      <Field name="address.address2">
        {({ field, form }) => (
          <Form.Field
            grow={1}
            label="Address 2"
            showLabel={!!field.value}
            error={
              form.touched.address?.[field.name.split('.')[1]] &&
              form.errors.address?.[field.name.split('.')[1]]
            }
          >
            <Input {...field} placeholder="Address 2" autoComplete="on" />
          </Form.Field>
        )}
      </Field>
      <Form.Group layout="horizontal">
        <Field name="address.city">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="City"
              showLabel={!!field.value}
              error={
                form.touched.address?.[field.name.split('.')[1]] &&
                form.errors.address?.[field.name.split('.')[1]]
              }
            >
              <Input {...field} placeholder="City" autoComplete="on" />
            </Form.Field>
          )}
        </Field>
        <Field name="address.state">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="State"
              showLabel={true}
              error={
                form.touched.address?.[field.name.split('.')[1]] &&
                form.errors.address?.[field.name.split('.')[1]]
              }
            >
              <StyledSelect
                clearable={false}
                isSearchable={true}
                disabled={
                  !pathOr(
                    false,
                    [form.values.address.country, 'regions', 'length'],
                    COUNTRY_REGIONS,
                  )
                }
                key={field.value}
                placeholder="State"
                filterOption={(option, inputValue) => {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) === 0 ||
                    option.value
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) === 0
                  );
                }}
                onChange={(value) => {
                  form.setFieldValue(field.name, value);
                  form.setFieldTouched(field.name);
                }}
                options={pathOr(
                  [],
                  [form.values.address.country, 'regions'],
                  COUNTRY_REGIONS,
                ).map(({ abbreviation, name }) => ({
                  label: name,
                  value: abbreviation,
                }))}
                value={field.value}
              />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
      <Form.Group layout="horizontal">
        <Field name="address.country">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="Country"
              showLabel={true}
              error={
                form.touched.address?.[field.name.split('.')[1]] &&
                form.errors.address?.[field.name.split('.')[1]]
              }
            >
              <StyledSelect
                clearable={false}
                isSearchable={true}
                placeholder="Country"
                filterOption={(option, inputValue) => {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) === 0 ||
                    option.value
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) === 0
                  );
                }}
                onChange={(value) => {
                  form.setFieldValue('address.state', '');
                  form.setFieldValue('address.postal_code', '');
                  form.setFieldValue('address.country', value);
                  form.setFieldTouched('address.state');
                  form.setFieldTouched('address.postal_code');
                  form.setFieldTouched('address.country');
                }}
                options={COUNTRIES.map(({ abbreviation, name }) => ({
                  label: name,
                  value: abbreviation,
                }))}
                value={field.value}
              />
            </Form.Field>
          )}
        </Field>
        <Field name="address.postal_code">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="Zip code"
              showLabel={!!field.value}
              error={
                form.touched.address?.[field.name.split('.')[1]] &&
                form.errors.address?.[field.name.split('.')[1]]
              }
            >
              <Input
                {...field}
                placeholder="Zip code"
                autoComplete="on"
                disabled={
                  !(form.values.address.country in COUNTRY_POSTAL_CODE_REGEX)
                }
              />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
    </Wrapper>
  );
};

export default ContactAddress;
