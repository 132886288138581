import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';

const Input = (props) => {
  return <components.Input {...props} autoComplete="none" />;
};

const DropdownIndicator = styled.div`
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  border: 1px solid;
  border-color: transparent transparent #979797 #979797;
  transform: rotate(-45deg);
`;

const StyledReactSelect = styled(ReactSelect)`
  font-size: 14px;
  cursor: pointer;

  .react-select__control {
    display: block;
    border-radius: 2px;
    border-color: #dedede !important;
  }

  .react-select__control--is-disabled {
    background-color: #f4f4f4;
  }

  .react-select__control--is-focused {
    box-shadow: none;
    border-color: #828282 !important;
  }

  :not(.react-select--is-disabled) {
    .react-select__control {
      background: white;
      cursor: pointer;
    }

    :hover {
      .react-select__control {
        border-color: #aaaaaa !important;
      }
    }
  }

  .react-select__value-container {
    position: unset;
  }

  .react-select__indicators {
    position: absolute;
    top: 0.6em;
    right: 0.8em;
    padding-right: 0;

    .react-select__indicator {
      padding: 0 8px;
    }

    .react-select__indicator-separator {
      background-color: inherit;
    }
  }

  .react-select__placeholder {
    color: #747474;
    line-height: 3.2em;
    padding-right: 1.8em;
  }

  .react-select__multi-value__label {
    font-size: inherit;
  }

  .react-select__multi-value__remove:hover {
    background-color: #2684ff;
    color: white;
  }

  input[id^='react-select-'] {
    opacity: 1 !important;
  }
`;

const Select = ({
  className,
  disabled,
  onChange,
  options,
  value,
  defaultValue,
  ...props
}) => {
  const findOption = (value) =>
    value == null
      ? value
      : Array.isArray(value)
      ? options.filter((option) => value.includes(option.value))
      : options.find((option) => option.value === value);
  return (
    <StyledReactSelect
      className={className}
      classNamePrefix="react-select"
      components={{ Input, DropdownIndicator }}
      isDisabled={disabled}
      onChange={(option, actionType) => {
        if (Array.isArray(option)) {
          return onChange(option.map((op) => op.value));
        } else {
          onChange((option || {}).value, actionType);
        }
      }}
      options={options}
      value={findOption(value)}
      defaultValue={findOption(defaultValue)}
      {...props}
    />
  );
};

export default styled(Select)``;
