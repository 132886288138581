export const OTHER_MATERIAL = 'OTHER_MATERIAL';
export const DEFAULT_UNITS = 'mm';

export const CONTACT_INFO_START_FIELDS = [
  'first_name',
  'last_name',
  'email',
  'company',
  'phone',
  'address.address1',
  'address.address2',
  'address.city',
  'address.state',
  'address.country',
  'address.postal_code',
];

export const CONTACT_INFO_FINISH_FIELDS = [
  'first_name',
  'last_name',
  'email',
  'company',
  'phone',
];

export const OPTIONS = ['options', 'material', 'process', 'description'];

export const PART_DETAILS = ['material', 'process'];
