/* eslint-disable camelcase */
import { StoreContext } from 'Store';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  INVALID_EMAIL,
  INVALID_PHONE,
  INVALID_THICKNESS,
  MATERIAL_DESCRIPTION_REQUIRED,
  REQUIRED,
  TOO_FEW_QUANTITIES,
  TOO_MANY_QUANTITIES,
  validationSchema,
} from 'components/RequestForQuoteForm/validationSchema';

import Button from '../ui/Button';
import Comments from './Comments';
import ContactAddress from './ContactAddress';
import ContactInformation from './ContactInformation';
import PartDetails from './PartDetails';
import Referrer from './Referrer';
import RequestedDeliveryDate from './RequestedDeliveryDate';
import RfqNumber from './RfqNumber';
import {
  CONTACT_INFO_START_FIELDS,
  CONTACT_INFO_FINISH_FIELDS,
  OPTIONS,
  OTHER_MATERIAL,
  PART_DETAILS,
} from './constants';
import * as status from './status';
import { parseQuantities } from './utils';

const SectionsWrapper = styled.div`
  padding: 40px 36px;
  background-color: rgba(244, 244, 244, 0.4);

  @media (min-width: 1160px) {
    display: flex;
    padding: 0;

    & > *:nth-child(1) {
      width: 420px;
    }

    & > *:nth-child(2) {
      width: 720px;
    }
  }
`;

const Section = styled.section`
  :not(:last-child) {
    margin-bottom: 32px;
  }

  h2 {
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    margin: 0 0 20px;
  }
`;

const SectionGroup = styled.div`
  &:not(:last-child) {
    padding-bottom: 32px;
  }

  @media (min-width: 1160px) {
    padding: 32px 36px;

    &:nth-child(1) {
      background-color: #f4f4f4;
    }
  }
`;

const Number = styled.span`
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border: 1px solid black;
  border-radius: 50%;
  margin-right: 8px;
  padding-left: 0.2em;
`;

const SubmitButton = styled(Button)`
  background-color: #2240f6;

  &:not([disabled]) {
    &:focus,
    &:hover {
      background-color: #4e66f7;
    }
    &:active {
      background-color: #1e39dd;
    }
  }

  &[disabled] {
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const Error = styled.span.attrs({
  role: 'alert',
})`
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  margin-top: 5px;
`;

const Divider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LogoLink = styled.a`
  display: block;
  padding: 16px;

  &:hover {
    opacity: 70%;
  }

  &:active {
    opacity: 50%;
  }
`;

const LogoImage = styled.img`
  display: block;
  width: 197px;
  max-width: 100%;
`;

const PoweredBy = ({ userGroupLocator }) => {
  const linkDestination = `https://www.paperlessparts.com?utm_campaign=Customer%20Referral%20Campaign&utm_source=customer-generated-quote&utm_medium=email&utm_term=${userGroupLocator}`;

  return (
    <Section>
      <Divider />
      <LogoContainer>
        <LogoLink
          href={linkDestination}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoImage
            src="https://s3.amazonaws.com/static.paperlessparts.com/images/powered-by-paperless-parts-badge.svg"
            alt="Powered by Paperless Parts"
          />
        </LogoLink>
      </LogoContainer>
    </Section>
  );
};

const pause = () => new Promise((resolve) => setTimeout(resolve, 1));

// Scrolls the page up to the first occurance of a field that could not
// be validated, if it exists
const scrollToError = async ({ max_quote_item_quantities }) => {
  // On the first call of this function, the error texts will not be fully
  // loaded instantly. We need to wait momentarily for everything to load in
  await pause();
  const errorTypes = [
    REQUIRED,
    INVALID_EMAIL,
    INVALID_PHONE,
    TOO_FEW_QUANTITIES,
    TOO_MANY_QUANTITIES({ max_quote_item_quantities }),
    MATERIAL_DESCRIPTION_REQUIRED,
    INVALID_THICKNESS,
  ];
  const divs = window.document.querySelectorAll('div');
  for (const div of divs) {
    if (errorTypes.includes(div.innerHTML)) {
      window.scrollTo(0, div.offsetTop);
      break;
    }
  }
};

const extractKeysRecursive = (obj) => {
  const keys = [];
  const recursiveExtractor = (currentObj, currentKey) => {
    for (const key in currentObj) {
      const newKey = currentKey ? `${currentKey}.${key}` : key;
      keys.push(newKey);
      if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
        recursiveExtractor(currentObj[key], newKey);
      }
    }
  };
  recursiveExtractor(obj, '');
  return keys;
};

const checkStart = (errors, touched, ls) => {
  const errorKeys = extractKeysRecursive(errors);
  const touchedKeys = extractKeysRecursive(touched);
  return ls.some((key) => {
    return (
      touchedKeys.includes(key) &&
      !errorKeys.includes(key) &&
      errorKeys.length !== 0
    );
  });
};
const checkPartStart = (errors, ls) => {
  const errorKeys = extractKeysRecursive(errors);
  return ls.some((key) => !errorKeys.includes(key) && errorKeys.length !== 0);
};

const checkFinished = (errors, touched, ls) => {
  const errorKeys = extractKeysRecursive(errors);
  const touchedKeys = extractKeysRecursive(touched);
  return (
    ls.every((key) => touchedKeys.includes(key)) &&
    ls.every((key) => !errorKeys.includes(key)) &&
    errorKeys.length !== 0
  );
};

const checkPartFinished = (errors, ls) => {
  const errorKeys = extractKeysRecursive(errors);
  return ls.every((key) => !errorKeys.includes(key) && errorKeys.length !== 0);
};

const RequestForQuoteForm = () => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const { globalState, updateRFQView, submit } = useContext(StoreContext);
  const { error, isSubmitting, isUploading, settings, files } = globalState;
  const {
    show_delivery_date_selector,
    show_notes_field,
    show_referral_field,
    show_rfq_number_field,
    show_contact_address_fields,
  } = settings.smart_rfq_settings;
  const { user_group_locator, show_paperless_branding } = settings;

  const formRef = useRef(null);

  useEffect(() => {
    if (files) {
      formRef.current.setFieldValue(
        'options',
        files
          .filter((x) => !x.error)
          .map((i) => ({
            file: {
              id: i.id,
              thickness: i.thickness,
            },
            process: i.process,
            material: i.material,
            supplier_material: i.supplier_material,
            process_finish: i.process_finish,
            quantities: parseQuantities(i.quantities),
          })),
      );
    }
  }, [files]);

  return (
    <Formik
      initialValues={{
        email: '',
        first_name: '',
        last_name: '',
        company: '',
        phone: '',
        phone_ext: '',
        requested_delivery_date: null,
        export_controlled: undefined,
        options: [],
        description: '',
        referrer: '',
        rfq_number: null,
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: 'USA',
          postal_code: '',
        },
      }}
      validationSchema={() => validationSchema(settings)}
      onSubmit={(values) => {
        const optionsPayload = values.options.map((opt) => {
          return {
            ...opt,
            material: opt.material === OTHER_MATERIAL ? null : opt.material,
            supplier_material:
              opt.supplier_material === OTHER_MATERIAL
                ? null
                : opt.supplier_material,
            process_finish: opt.process_finish ? opt.process_finish : [],
          };
        });
        const payload = {
          ...values,
          address: !values.address.state
            ? null
            : {
                ...values.address,
                address1: values.address.address1 || null,
                address2: values.address.address2 || null,
                city: values.address.city || null,
                postal_code: values.address.postal_code || null,
              },
          options: optionsPayload,
        };
        submit(payload);
      }}
    >
      {(form) => {
        const { errors, touched, values } = form;
        formRef.current = form;
        checkStart(errors, touched, CONTACT_INFO_START_FIELDS) &&
          updateRFQView(status.CONTACT_STARTED);
        checkFinished(errors, touched, CONTACT_INFO_FINISH_FIELDS) &&
          updateRFQView(status.CONTACT_FINISHED);
        checkPartStart(errors, PART_DETAILS) &&
          updateRFQView(status.STARTED_PART_DETAILS);
        checkPartFinished(errors, OPTIONS) &&
          updateRFQView(status.FINISHED_PART_DETAILS);
        values.requested_delivery_date &&
          updateRFQView(status.REQUESTED_DELIVERY_DATE);
        values.description && updateRFQView(status.SUBMITTED_ADDITIONAL_NOTES);
        values.referrer && updateRFQView(status.SUBMITTED_SOURCE);
        isUploading && updateRFQView(status.SUBMITTED_FILE);

        const projectDetailsStepNumber = show_delivery_date_selector ? 3 : 2;
        const notesStepNumber = projectDetailsStepNumber + 1;
        const referralStepNumber = show_notes_field
          ? notesStepNumber + 1
          : notesStepNumber;
        return (
          <Form autoComplete="on">
            <SectionsWrapper>
              <SectionGroup>
                <Section>
                  <h2>
                    <Number>1</Number>
                    Contact Information
                  </h2>
                  <ContactInformation />
                  {show_contact_address_fields && <ContactAddress />}
                </Section>

                {show_delivery_date_selector && (
                  <Section>
                    <h2>
                      <Number>2</Number>
                      Requested Delivery Date
                    </h2>

                    <RequestedDeliveryDate />
                  </Section>
                )}
              </SectionGroup>

              <SectionGroup>
                <Section>
                  <h2>
                    <Number>{projectDetailsStepNumber}</Number>
                    Project Details
                  </h2>
                  {show_rfq_number_field && <RfqNumber />}

                  <PartDetails />
                </Section>

                {show_notes_field && (
                  <Section>
                    <h2>
                      <Number>{notesStepNumber}</Number>
                      What else should we know about your project?
                    </h2>

                    <Comments />
                  </Section>
                )}

                <Section>
                  {show_referral_field && (
                    <>
                      <h2>
                        <Number>{referralStepNumber}</Number>
                        How did you hear about us?
                      </h2>

                      <Referrer />
                    </>
                  )}

                  <SubmitButtonContainer>
                    {error && <Error>{error}</Error>}
                    <SubmitButton
                      type="submit"
                      loading={isSubmitting}
                      onClick={() => {
                        setSubmitClicked(true);
                        scrollToError(settings);
                      }}
                    >
                      Submit
                    </SubmitButton>
                    {submitClicked && Object.keys(errors).length !== 0 && (
                      <Error>
                        Unable to submit. Please fill all required fields.
                      </Error>
                    )}
                  </SubmitButtonContainer>
                </Section>
                {show_paperless_branding ? (
                  <PoweredBy userGroupLocator={user_group_locator} />
                ) : null}
              </SectionGroup>
            </SectionsWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestForQuoteForm;
