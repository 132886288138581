import { StoreContext } from 'Store';
import { Field } from 'formik';
import { useContext } from 'react';
import styled from 'styled-components';

import Form from '../ui/Form';
import Select from '../ui/Select';
import Uploads from './Uploads';

const StyledSelect = styled(Select)`
  font-size: 14px;
`;

const ExportControlledHeader = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
`;

const ExportControlledMessage = styled.span`
  margin-bottom: 15px;
`;

const ExportControlledSection = styled.div`
  background: #fff;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 20px;
  width: 100%;
`;

const QuantitiesMessage = styled.div`
  background: #eee;
  border: 1px solid #dedede;
  color: black;
  margin-bottom: 15px;
  padding: 20px;
`;

const PartDetails = () => {
  const { globalState } = useContext(StoreContext);
  const { settings, tempFileIds } = globalState;

  return (
    <>
      {settings.ddtc_registered && (
        <ExportControlledSection>
          <ExportControlledHeader>
            Export Controlled Regulations
          </ExportControlledHeader>
          <ExportControlledMessage>
            Is any data relating to this project subject to Export Control
            regulations, including, but not limited to, the International
            Traffic in Arms Regulations (ITAR), 22 C.F.R. 120-130?
          </ExportControlledMessage>
          <Field name="export_controlled">
            {({ field, form }) => (
              <Form.Field
                error={form.touched[field.name] && form.errors[field.name]}
              >
                <StyledSelect
                  clearable={false}
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  options={[
                    {
                      value: true,
                      label:
                        'Yes, this project contains data subject to Export Control regulations',
                    },
                    {
                      value: false,
                      label:
                        'No, this project does not contain data subject to Export Control regulations',
                    },
                  ]}
                  placeholder="---"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={field.value}
                />
              </Form.Field>
            )}
          </Field>
        </ExportControlledSection>
      )}
      <Field name="options">
        {({ field, form }) => {
          return (
            <>
              {(form.values.options.length > 0 || tempFileIds.length > 0) && (
                <QuantitiesMessage>
                  {`Enter up to ${settings.max_quote_item_quantities} quantities per part. If you don't know the
                  process or material, leave them blank and we will quote your
                  part to the technical specification you provide.`}
                </QuantitiesMessage>
              )}
              <Uploads
                errors={form.errors[field.name]}
                touched={form.touched[field.name]}
                setOptionsFieldTouched={(index, subField) =>
                  form.setFieldTouched(
                    `${field.name}[${index}].${subField}`,
                    true,
                  )
                }
                onChange={(value) => form.setFieldValue(field.name, value)}
                onBlur={(index) =>
                  form.setFieldTouched(
                    `${field.name}[${index}].quantities`,
                    true,
                  )
                }
              />
            </>
          );
        }}
      </Field>
    </>
  );
};

export default PartDetails;
