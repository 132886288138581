export const CONTACT_STARTED = 'HAS_STARTED_CONTACT_INFO';
export const CONTACT_FINISHED = 'HAS_FINISHED_CONTACT_INFO';

export const REQUESTED_DELIVERY_DATE = 'HAS_REQUESTED_DELIVERY_DATE';

export const STARTED_PART_DETAILS = 'HAS_STARTED_PART_DETAILS';
export const FINISHED_PART_DETAILS = 'HAS_FINISHED_PART_DETAILS';

export const SUBMITTED_FILE = 'HAS_SUBMITTED_FILE';
export const SUBMITTED_ADDITIONAL_NOTES = 'HAS_SUBMITTED_ADDITIONAL_NOTES';
export const SUBMITTED_SOURCE = 'HAS_SUBMITTED_SOURCE';
