import styled from 'styled-components';

import { CloseIcon } from 'components/ui/Icons';

const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  color: #aaa;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;

  > svg {
    display: block;
  }
`;

const ErrorWrapper = styled.div`
  color: red;
  button {
    margin-left: 5px;
    vertical-align: bottom;
  }
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const UploadError = ({ file, onDelete }) => {
  return (
    <ErrorWrapper>
      <span>
        {file.error.replace('.', '')} ({file.filename}
        ).
      </span>
      <DeleteButton type="button" onClick={onDelete}>
        <CloseIcon />
      </DeleteButton>
    </ErrorWrapper>
  );
};

export default UploadError;
