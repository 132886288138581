import React from 'react';
import { createGlobalStyle } from 'styled-components';

import Store from '../Store';
import Content from './Content';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Libre Franklin', sans-serif !important;
    line-height: initial !important;
    margin: 0;
    overflow-x: hidden; // NOTE: this is for some odd reason the page adds horizontal scroll
    padding: 0;
  }

  .rs-picker-cascader-menu-items {
    > *:first-of-type {
      width: 100% !important;
    }
  }

  .rs-picker-cascader-menu-column {
    width: 25% !important;
    height: 250px !important;

    li {
      a {
        height: unset;
      }
    }
  }
`;

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <GlobalStyle />
        <Store>
          <Content />
        </Store>
      </React.Fragment>
    );
  }
}

export default App;
