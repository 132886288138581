import { Field } from 'formik';
import styled from 'styled-components';

import Form from '../ui/Form';
import Input from '../ui/TextInput';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const RfqNumber = () => {
  return (
    <Wrapper>
      <Field name="rfq_number">
        {({ form, field }) => (
          <Form.Field
            error={form.touched[field.name] && form.errors[field.name]}
          >
            <Input maxLength={255} {...field} placeholder="RFQ #" />
          </Form.Field>
        )}
      </Field>
    </Wrapper>
  );
};

export default RfqNumber;
