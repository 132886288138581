import styled from 'styled-components';

import Layout from '../components/common/Layout';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 50px solid #f4f4f4;
  background-color: #fbfbfb;
  padding: 250px 10px;
`;

const Text1 = styled.div`
  color: #292929;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const Text2 = styled.div`
  display: inline-block;
  color: #4a4a4a;
  font-size: 20px;
  max-width: 500px;
`;

const EmailIcon = () => {
  return (
    <svg width="103" height="66" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="a" d="M.012.05h102.787v65.938H.012z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M29.989 27.755l23.568 6.057L40.165 55.24 29.989 27.755zm67.385 6.34l-24.127-7.562L87.194 6.608l10.18 27.487zm-32.993 2.499a2.305 2.305 0 0 0 2.458-.908l3.641-5.204 23.566 7.386-48.898 18.074 13.08-20.93 6.153 1.582zm-.398-4.848L34.001 24.04l47.727-17.64-17.745 25.345zm38.53 2.991L90.782 3.053A4.565 4.565 0 0 0 88.39.473a4.572 4.572 0 0 0-3.518-.135L27.667 21.484a4.568 4.568 0 0 0-2.583 2.39 4.561 4.561 0 0 0-.135 3.514L36.68 59.072a4.567 4.567 0 0 0 2.391 2.58 4.587 4.587 0 0 0 3.518.135l57.206-21.145a4.6 4.6 0 0 0 2.717-5.905zM23.838 58.343l-8.666 3.204a2.297 2.297 0 1 0 1.596 4.31l8.665-3.204a2.297 2.297 0 1 0-1.595-4.31M7.557 43.443c.265 0 .535-.046.798-.143l5.35-1.977a2.297 2.297 0 1 0-1.597-4.31L6.76 38.99a2.297 2.297 0 0 0 .798 4.453M5.477 54.418l-3.975 1.47a2.297 2.297 0 1 0 1.596 4.309l3.975-1.47a2.297 2.297 0 1 0-1.596-4.31"
          fill="#344DE6"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
};

const Success = () => {
  return (
    <Layout>
      <Wrapper>
        <div>
          <div style={{ marginBottom: '40px' }}>
            <EmailIcon />
          </div>
          <Text1>Thank you for submitting your quote request.</Text1>
          <Text2>
            Your files are being reviewed and a quote will be emailed to you
            shortly.
          </Text2>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Success;
