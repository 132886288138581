import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
  display: inline-block;

  input {
    display: none;

    &:checked:not([disabled]) + .indicator {
      background-color: ${(props) => props.checkedColor};
    }

    &:checked + .indicator::after {
      left: 61%;
    }

    &[disabled] + .indicator {
      background: #aaa;
      ::after {
        background: #828282;
      }
    }
  }

  .indicator {
    position: relative;
    display: block;
    width: 3.3em;
    height: 1.4em;
    line-height: 1.1em;
    cursor: pointer;
    outline: none;
    user-select: none;

    color: white;
    background: ${(props) => props.uncheckedColor};
    border-radius: 2em;
    padding: 2px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: 100%;
      transition: all 0.4s ease;
    }

    &::after {
      top: 0.14em;
      left: 0.11em;
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
      width: 1.14em;
      height: 1.14em;
    }

    &::before {
      display: none;
    }
  }

  .inner {
    display: inline-block;
    min-width: 2.2em;
    position: absolute;
    top: 0;
    ${(props) => (props.checked ? 'left' : 'right')}: 0.28em;
    font-size: 0.8em;
    font-weight: 600;
    text-align: center;
    line-height: 1.7em;
  }
`;

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.isControlled = props.checked !== undefined;
    this.state = {
      checked: this.isControlled ? props.checked : props.defaultChecked,
    };
  }

  handleChange = (e) => {
    this.setState({ checked: e.target.checked });
    this.props.onChange && this.props.onChange(e);
  };

  render() {
    const {
      className,
      checkedColor = '#ff922d',
      uncheckedColor = '#828282',
      checkedChildren,
      uncheckedChildren,
      checked,
      onChange,
      ...props
    } = this.props;
    const { checked: checkedFromState } = this.state;
    const isChecked = this.isControlled ? checked : checkedFromState;
    const onChangeHandler = this.isControlled ? onChange : this.handleChange;
    return (
      <Wrapper
        className={className}
        checkedColor={checkedColor}
        uncheckedColor={uncheckedColor}
        checked={isChecked}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChangeHandler}
          {...props}
        />
        <span className="indicator">
          <span className="inner">
            {isChecked && checkedChildren}
            {!isChecked && uncheckedChildren}
          </span>
        </span>
      </Wrapper>
    );
  }
}

export default styled(Toggle)``;
