import styled from 'styled-components';

import Form from '../ui/Form';
import Input from '../ui/TextInput';

const DXFInput = styled(Input)`
  > input {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }
`;

const DXFWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 10px;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 10px;
  }
  > :not(:last-child) {
    padding-right: 15px;
  }
`;

const DXFInputs = ({
  value,
  onUnitsThicknessChange,
  thicknessError,
  thicknessTouched,
  setThicknessTouched,
}) => {
  const handleBlur = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    setThicknessTouched(true);
    onUnitsThicknessChange({
      ...value,
      thickness: e.target.value || '',
    });
  };

  return (
    <DXFWrapper>
      <Form.Field label="Material Thickness (Inches)">
        <DXFInput
          onBlur={handleBlur}
          error={thicknessTouched ? thicknessError : undefined}
        />
      </Form.Field>
    </DXFWrapper>
  );
};

export default DXFInputs;
