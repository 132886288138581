import RequestForQuoteForm from '../components/RequestForQuoteForm/RequestForQuoteForm';
import Layout from '../components/common/Layout';

const RequestForQuote = (props) => {
  return (
    <Layout>
      <RequestForQuoteForm {...props} />
    </Layout>
  );
};

export default RequestForQuote;
