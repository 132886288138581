import { StoreContext } from 'Store';
import path from 'ramda/src/path';
import { useContext } from 'react';
import ReactDropzone from 'react-dropzone';
import styled from 'styled-components';

import { UploadDiskIcon } from 'components/ui/Icons';

import Button from '../ui/Button';
import Part from './Part';

const Wrapper = styled.div``;

const Dropzone = styled(ReactDropzone)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 40px 20px;
  margin-bottom: 25px;
  cursor: pointer;
`;

const UploadButtonDropzone = styled(ReactDropzone)`
  display: block;
  border: none;
  padding: 0;
`;

const DropzoneIcon = styled.div`
  color: #292929;
  margin-bottom: 5px;
`;

const DropzoneText1 = styled.div`
  color: #292929;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const DropzoneText2 = styled.div`
  color: #4a4a4a;
  font-size: 14px;
  line-height: 19px;
`;

const UploadButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  > * {
    width: 50%;
  }
`;

const UploadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #2240f6;
  border: 1px solid #80a0f4;
  font-weight: 500;
  line-height: 21px;
  svg {
    margin-right: 10px;
  }
`;

const Parts = styled.div`
  margin-bottom: 15px;
`;

const UploadIcon = styled(UploadDiskIcon)`
  color: #292929;
`;

const Uploads = ({ onBlur, errors, touched, setOptionsFieldTouched }) => {
  const { globalState, handleUpload } = useContext(StoreContext);
  const { fileIds, tempFileIds } = globalState;

  return (
    <Wrapper>
      {fileIds.length === 0 && tempFileIds.length === 0 && (
        <Dropzone onDrop={handleUpload}>
          <div>
            <DropzoneIcon>
              <UploadIcon size={30} />
            </DropzoneIcon>
            <DropzoneText1>Upload part files</DropzoneText1>
            <DropzoneText2>
              For fastest quoting, upload 3D part files. We accept .stp, .step,
              .sldprt.
              <br />
              We also accept .3dm, .3ds, .3dxml, .3mf, .catpart, .catshape,
              .cgr, .dae,
              <br />
              .doc, .dwf, .dwfx, .dwg, .dxf, .exp, .fbx, .gif, .glb, .ifc,
              .iges, .igs, .ipt, .jpeg,
              <br />
              .jpg, .jt, .model, .neu, .obj, .par, .pdf, .png, .prc, .prt, .psm,
              .sab, .sat,
              <br />
              .stl, .stpz, .vrml, .wrl, .x_b, .x_t, .zip
            </DropzoneText2>
          </div>
        </Dropzone>
      )}
      {(fileIds.length > 0 || tempFileIds.length > 0) && (
        <div>
          <Parts>
            {fileIds.length > 0 &&
              fileIds.map((fileId, index) => (
                <Part
                  key={fileId || index}
                  index={index}
                  fileId={fileId}
                  thicknessError={
                    errors && path(['file', 'thickness'], errors[index])
                  }
                  thicknessTouched={
                    touched && path(['file', 'thickness'], touched[index])
                  }
                  setThicknessTouched={() =>
                    setOptionsFieldTouched(index, 'file.thickness')
                  }
                  onBlur={() => onBlur(index)}
                />
              ))}
            {tempFileIds.length > 0 &&
              tempFileIds.map((fileId, index) => (
                <Part
                  key={`temp-${fileId || index}`}
                  index={fileIds.length + index}
                  isTemp={true}
                  fileId={fileId}
                  thicknessError={
                    errors && path(['file', 'thickness'], errors[index])
                  }
                  thicknessTouched={
                    touched && path(['file', 'thickness'], touched[index])
                  }
                  setThicknessTouched={() =>
                    setOptionsFieldTouched(index, 'file.thickness')
                  }
                  onBlur={() => onBlur(index)}
                />
              ))}
          </Parts>
          <UploadButtonContainer>
            <UploadButtonDropzone onDrop={handleUpload}>
              <UploadButton variant="white">
                <UploadIcon size={30} />
                <span>Add more part files</span>
              </UploadButton>
            </UploadButtonDropzone>
          </UploadButtonContainer>
        </div>
      )}
    </Wrapper>
  );
};

export default Uploads;
