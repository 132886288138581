import React from 'react';
import styled from 'styled-components';

import Spinner from './Spinner';

const StyledButton = styled.button`
  position: relative;
  font-family: inherit;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;

  min-width: 120px;
  text-align: center;

  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #ff771a;
  padding: 1em 1.3em;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #ff922d;
  }

  &:focus {
    background-color: #ff922d;
    border-color: rgba(0, 0, 0, 0.04);
  }

  &:active {
    background-color: #eb8629;
    border-color: rgba(0, 0, 0, 0.04);
  }

  &[disabled] {
    background-color: #dedede !important;
    border-color: transparent !important;
    color: #fff;
    font-weight: 500;
    cursor: default;
  }

  ${(props) =>
    props.variant === 'secondary' &&
    `
    background-color: #0083b7;

    &:hover {
      background-color: #2ea4d2;
    }

    &:focus {
      background-color: #2ea4d2;
    }

    &:active {
      background-color: #339bc5;
    }
  `}

  ${(props) =>
    props.variant === 'white' &&
    `
    border: 1px solid rgb(217, 217, 217);
    color: rgba(0,0,0,.65);

    &, &:focus, &:hover, &:active {
      background-color: #fff;
    }

    &:not([disabled]) {
      &:focus, &:hover {
        border-color: #40a9ff;
        color: #40a9ff;
      }

      &:active {
        border-color: #096dd9;
        color: #096dd9;
      }
    }
  `}

  ${(props) =>
    props.loading &&
    `
    color: transparent !important;
  `}

  ${Spinner} {
    color: ${(props) => (props.variant === 'white' ? '#40a9ff' : '#ffffff')};
    font-size: 18px;
  }

  &[disabled] ${Spinner} {
    color: #fff;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
`;

// const IconContainer = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 10px;
//   transform: translateY(-50%);
// `;

class Button extends React.Component {
  static propTypes = {
    // variant
    // loading
  };

  render() {
    const {
      type = 'button',
      loading,
      // icon,
      children,
      ...props
    } = this.props;
    return (
      <StyledButton loading={loading} type={type} {...props}>
        {children}
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {/* {icon &&
          <IconContainer>
            {icon}
          </IconContainer>} */}
      </StyledButton>
    );
  }
}

export default styled(Button)``;
