/* eslint-disable camelcase */
import { array, boolean, lazy, mixed, object, string } from 'yup';

import {
  COUNTRIES,
  COUNTRY_REGIONS,
  COUNTRY_POSTAL_CODE_REGEX,
} from '@frontend/addresses';

import { OTHER_MATERIAL } from 'components/RequestForQuoteForm/constants';

export const REQUIRED = 'This field is required.';
export const INVALID_EMAIL = 'Invalid email.';
export const INVALID_PHONE = 'Invalid phone.';
const INVALID_PHONE_EXT = 'Invalid phone ext.';
export const TOO_FEW_QUANTITIES = 'At least one quantity is required.';
export const TOO_MANY_QUANTITIES = ({ max_quote_item_quantities }) =>
  `More than ${max_quote_item_quantities} quantities are not allowed.`;
export const MATERIAL_DESCRIPTION_REQUIRED =
  'Should not be empty when "Other (See Notes)" option is selected.';
export const INVALID_THICKNESS = 'Please enter a valid number.';
export const validationSchema = ({
  max_quote_item_quantities,
  smart_rfq_settings: { show_referral_field, show_contact_address_fields },
  ddtc_registered,
}) =>
  lazy((values) => {
    const shape = {
      email: string().email(INVALID_EMAIL).required(REQUIRED),
      first_name: string().required(REQUIRED),
      last_name: string().required(REQUIRED),
      company: string().required(REQUIRED),
      phone: string()
        .required(REQUIRED)
        .min(10, INVALID_PHONE)
        .matches(/^[0-9]+$/, INVALID_PHONE),
      phone_ext: string().max(10, INVALID_PHONE_EXT),
      options: array().of(
        object().shape({
          quantities: array()
            .min(1, TOO_FEW_QUANTITIES)
            .max(
              max_quote_item_quantities,
              TOO_MANY_QUANTITIES({ max_quote_item_quantities }),
            ),
          file: object().shape({
            id: string(),
            thickness: string()
              .nullable()
              .min(1, REQUIRED)
              .test('valid-thickness', INVALID_THICKNESS, (value) =>
                value
                  ? value.indexOf('.') >= value.lastIndexOf('.')
                  : value === null,
              ),
          }),
          process: mixed().notRequired(),
          material: mixed().notRequired(),
          process_finish: mixed().notRequired(),
        }),
      ),
      description: values.options.find(
        (opt) =>
          opt.material === OTHER_MATERIAL ||
          opt.supplier_material === OTHER_MATERIAL,
      )
        ? string().required(MATERIAL_DESCRIPTION_REQUIRED)
        : mixed(),
      referrer: show_referral_field
        ? string().required(REQUIRED)
        : string().notRequired(),
      rfq_number: string().notRequired().nullable(),
      address: show_contact_address_fields
        ? object().shape({
            address1: string().max(250).nullable(),
            address2: string().max(250).nullable(),
            city: string().max(100).nullable(),
            country: string()
              .oneOf(
                COUNTRIES.map((country) => country.abbreviation),
                'Invalid country.',
              )
              .required('This field is required.'),
            postal_code: string()
              .test(
                'postalCodeIsNull',
                'Postal code invalid',
                function (value) {
                  if (value === null || value === '') {
                    return true; // Validation passed, postal code is null or empty
                  }
                  // Perform your postal code validation here based on the selected country
                  const selectedCountry = this.parent.country; // Access the parent values
                  if (
                    Object.prototype.hasOwnProperty.call(
                      COUNTRY_POSTAL_CODE_REGEX,
                      selectedCountry,
                    )
                  ) {
                    return COUNTRY_POSTAL_CODE_REGEX[selectedCountry].test(
                      value,
                    );
                  }
                  return false;
                },
              )
              .nullable(),
            state: string().when(['country'], (selectedCountry, schema) => {
              const countryRegion = COUNTRY_REGIONS[selectedCountry];
              if (countryRegion) {
                return schema.oneOf(
                  countryRegion.regions.map((region) => region.abbreviation),
                  `Invalid ${countryRegion.regionLabel}`,
                );
              }
            }),
          })
        : mixed().notRequired(),
    };

    if (ddtc_registered) {
      shape.export_controlled = boolean().required(REQUIRED);
    }

    return object().shape(shape);
  });
