import { StoreContext } from 'Store';
import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import Form from '../ui/Form';
import { CloseIcon } from '../ui/Icons';
import { File } from './File';
import SupportingFile from './SupportingFile';

const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  color: #aaa;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;

  > svg {
    display: block;
  }
`;

const DetailsWrapper = styled(Form.Field)`
  flex-grow: 1;
  min-width: 0;
`;

const Container = styled.div`
  border: 1px solid #cecece;
  margin-bottom: 15px;
  :last-child {
    margin-bottom: none;
  }
  .rs-picker-menu {
    left: ${(props) =>
      `${
        props.materialMenuPosition ? `${props.materialMenuPosition.x}px` : '0'
      } !important`};
    top: ${(props) =>
      `${
        props.materialMenuPosition
          ? `${props.materialMenuPosition.y}px`
          : 'unset'
      } !important`};
    width: ${(props) =>
      `${
        props.materialMenuPosition
          ? `${props.materialMenuPosition.width}px`
          : 'unset'
      } !important`};
    right: 0 !important;
  }
`;

const Content = styled.div`
  padding: 10px;
`;

const FileInfoWrapper = styled.div`
  display: flex;
`;

const PartHeader = styled.div`
  align-items: center;
  background: #f4f4f4;
  border-bottom: 1px solid #cecece;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
`;

export const PartContext = React.createContext({});

const Part = ({
  index,
  isTemp,
  fileId,
  thicknessError,
  thicknessTouched,
  onBlur,
  setThicknessTouched,
  store,
}) => {
  const {
    getFile,
    getTempFile,
    handleFileDelete,
    handleSupportingFileDelete: onDeleteSupportingFile,
  } = useContext(StoreContext);
  const [materialMenuPosition, setMaterialMenuPosition] = useState(undefined);
  const containerRef = useRef(null);
  const value = isTemp ? getTempFile(fileId) : getFile(fileId);
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this part?')) {
      handleFileDelete(value); //value is file
    }
  };

  return value ? (
    <PartContext.Provider
      value={{
        containerRef,
        setMaterialMenuPosition,
      }}
    >
      <Container ref={containerRef} materialMenuPosition={materialMenuPosition}>
        <PartHeader>
          {`PART ${index + 1}`}
          <DeleteButton type="button" onClick={handleDelete}>
            <CloseIcon />
          </DeleteButton>
        </PartHeader>
        <Content>
          <File
            isTemp={isTemp}
            index={index}
            value={value}
            onDelete={handleDelete}
            onBlur={onBlur}
            thicknessError={thicknessError}
            thicknessTouched={thicknessTouched}
            setThicknessTouched={setThicknessTouched}
            store={store}
          />
          {value.supportingFiles && value.supportingFiles.length > 0 && (
            <FileInfoWrapper>
              <DetailsWrapper label="Supporting files" />
            </FileInfoWrapper>
          )}
          {value.supportingFiles &&
            value.supportingFiles.map((supportingFileId) => (
              <SupportingFile
                key={supportingFileId}
                id={supportingFileId}
                parentId={value.id}
                onDelete={onDeleteSupportingFile}
              />
            ))}
        </Content>
      </Container>
    </PartContext.Provider>
  ) : null;
};

export default Part;
