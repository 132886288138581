import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 600px;

  h1 {
    color: #292929;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 15px;
  }

  @media (min-width: 1160px) {
    max-width: 1160px;
  }
`;

const Layout = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default Layout;
