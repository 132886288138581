import 'core-js';
import 'react-app-polyfill/ie11';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';

import { API_URL } from './api';
import App from './screens/App';

/* eslint-disable */
console.info('API:', API_URL);
/* eslint-enable */

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
