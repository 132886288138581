import styled from 'styled-components';

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #102ee2;
  border-radius: 100px;
  transition: width 400ms ease;
`;

const ProgressRail = styled.div`
  position: relative;
  font-size: 14px;
  height: 0.7em;
  background-color: #aaaaaa;
  border-radius: 100px;
`;

const Progress = ({ value }) => {
  return (
    <ProgressRail>
      <ProgressBar style={{ width: `${value * 100}%` }} />
    </ProgressRail>
  );
};

export default Progress;
