import React from 'react';
import styled from 'styled-components';

const Group = styled.div`
  ${(props) =>
    (props.layout === 'vertical' || props.layout === undefined) &&
    `
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`} ${(props) =>
    props.layout === 'horizontal' &&
    `
display: flex;
& > *:not(:last-child) {
    padding-right: 20px;
}
`};
`;

const FieldContext = React.createContext({
  error: false,
});

const FieldWrapper = styled.div`
  width: 100%;

  ${(props) =>
    props.grow !== undefined &&
    `
    flex: ${props.grow} 0 0;
  `};

  > label {
    display: block;
    color: #828282;
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height: 15px;
    padding-top: 5px;
    ${(props) =>
      !props.showLabel &&
      `
    visibility: hidden;
  `};
  }
`;

const FieldMessage = styled.div`
  color: ${(props) => (props.isError ? 'red' : 'green')};
  font-size: 12px;
  line-height: 1.5;
`;

const Field = ({
  className,
  grow,
  label,
  showLabel = true,
  error,
  hint,
  children,
}) => {
  return (
    <FieldContext.Provider value={{ error: !!error }}>
      <FieldWrapper className={className} grow={grow} showLabel={showLabel}>
        {label && <label>{label}</label>}
        {children}
        {(error || hint) && (
          <FieldMessage isError={!!error}>{error || hint}</FieldMessage>
        )}
      </FieldWrapper>
    </FieldContext.Provider>
  );
};

export default {
  FieldContext,
  Field,
  Group,
};
