import { useCallback, useContext, useEffect, useRef } from 'react';
import { Cascader } from 'rsuite';
import 'rsuite/dist/styles/rsuite.min.css';
import styled from 'styled-components';

import { PartContext } from './Part';

const Wrapper = styled.div`
  position: relative;
  margin-right: 10px;
  width: 100%;
`;

const StyledCascader = styled(Cascader)`
  a {
    height: 38px;
  }

  .rs-picker-toggle-value {
    color: #333333 !important;
    text-overflow: ellipsis;
    max-width: 270px;
  }

  .rs-picker-default {
    background-color: #ffffff;
    border-color: #dedede !important;
    border-radius: 2px;
    padding: 9px 11px;
  }

  .rs-picker-toggle-clean {
    font-size: 17px;
    top: 7px;
    right: 25px;

    :hover {
      color: #333333;
    }
  }

  .rs-picker-toggle-caret {
    color: #979797;
    font-size: 18px;
    right: 10px;
    top: 7px;
  }

  .rs-picker-toggle-placeholder {
    color: #747474 !important;
    left: 10px;
    position: absolute;
    top: 8px;
  }

  .rs-picker-toggle {
    background-color: #ffffff;
    border-color: #dedede !important;
    border-radius: 2px;
    padding-top: 9px !important;

    :hover {
      border-color: #aaaaaa !important;
      z-index: unset;
    }
    :not(:hover) {
      z-index: unset;
    }
    :active {
      border-color: #aaaaaa !important;
    }
    :focus {
      border-color: #aaaaaa !important;
    }
  }
`;

const MaterialSelector = ({
  disabled,
  materialOptions,
  placeholder,
  materialId,
  onChange,
  supplierMaterialId,
}) => {
  const menuRef = useRef(null);
  const { containerRef, setMaterialMenuPosition } = useContext(PartContext);

  const updateMaterialMenuPosition = useCallback(
    (menuRef, containerRef) => {
      setMaterialMenuPosition({
        y:
          menuRef.current.offsetTop +
          menuRef.current.getBoundingClientRect().height,
        x: containerRef.current.getBoundingClientRect().left,
        width: containerRef.current.getBoundingClientRect().width,
      });
    },
    [setMaterialMenuPosition],
  );

  useEffect(() => {
    if (menuRef.current != null) {
      updateMaterialMenuPosition(menuRef, containerRef);
    }
  }, [containerRef, updateMaterialMenuPosition]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      updateMaterialMenuPosition(menuRef, containerRef);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef, updateMaterialMenuPosition]); // Empty array ensures that effect is only run on mount

  const handleSelect = (node, activePaths) => {
    if (node.isLeaf) {
      if (activePaths.length === 4) {
        onChange({
          materialId: activePaths[2].value,
          supplierMaterialId: activePaths[3].value,
        });
      } else if (
        activePaths.length === 3 &&
        (activePaths[2].children || []).length === 0
      ) {
        onChange({
          materialId: activePaths[2].value,
          supplierMaterialId: null,
        });
      }
    }
  };

  const handleClean = () => {
    onChange({
      materialId: null,
      supplierMaterialId: null,
    });
  };

  return (
    <Wrapper ref={menuRef}>
      <StyledCascader
        block
        container={containerRef.current}
        data={materialOptions}
        disabled={disabled}
        onClean={handleClean}
        onClose={() => {}}
        onSelect={handleSelect}
        placeholder={placeholder}
        placement="auto"
        renderValue={(_, activePaths) => {
          return activePaths[activePaths.length - 1].label;
        }}
        renderMenuItem={(label) => {
          return <div style={{ fontSize: '12px' }}>{label}</div>;
        }}
        value={supplierMaterialId || materialId}
      />
    </Wrapper>
  );
};

export default MaterialSelector;
