import { Field } from 'formik';
import styled from 'styled-components';

import Form from '../ui/Form';
import Input from '../ui/TextInput';

const Wrapper = styled.div`
  margin-top: -20px;
`;

const FakePhoneInput = (props) => {
  const { setFieldValue } = props;
  return (
    <Input
      {...props}
      autoComplete="off"
      mask="(999) 999-9999"
      maskChar={null}
      placeholder="Phone"
      onChange={(e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        setFieldValue('phone', e.target.value);
        setFieldValue('phoneAutocomplete', e.target.value);
      }}
    />
  );
};

const ContactInformation = () => {
  return (
    <Wrapper>
      <Form.Group layout="horizontal">
        <Field name="first_name">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="First Name"
              showLabel={!!field.value}
              error={form.touched[field.name] && form.errors[field.name]}
            >
              <Input {...field} placeholder="First Name" autoComplete="on" />
            </Form.Field>
          )}
        </Field>
        <Field name="last_name">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="Last Name"
              showLabel={!!field.value}
              error={form.touched[field.name] && form.errors[field.name]}
            >
              <Input {...field} placeholder="Last Name" autoComplete="on" />
            </Form.Field>
          )}
        </Field>
      </Form.Group>

      <Field name="email">
        {({ field, form }) => (
          <Form.Field
            label="Email Address"
            showLabel={!!field.value}
            error={form.touched[field.name] && form.errors[field.name]}
          >
            <Input
              {...field}
              placeholder="Email Address"
              type="email"
              autoComplete="on"
            />
          </Form.Field>
        )}
      </Field>

      <Field name="company">
        {({ field, form }) => (
          <Form.Field
            label="Company"
            showLabel={!!field.value}
            error={form.touched[field.name] && form.errors[field.name]}
          >
            <Input {...field} placeholder="Company" autoComplete="on" />
          </Form.Field>
        )}
      </Field>

      <Form.Group layout="horizontal">
        {/*NOTE: we are using a hidden fake field to drive input autocomplete
        while being able to keep the input mask for phone number*/}
        <Field
          name="phoneAutocomplete"
          type="tel"
          autoComplete="tel"
          style={{ display: 'none' }}
        />
        <Field name="phone">
          {({ field, form }) => (
            <Form.Field
              grow={1}
              label="Phone"
              showLabel={!!field.value}
              error={form.touched[field.name] && form.errors[field.name]}
            >
              <FakePhoneInput {...field} setFieldValue={form.setFieldValue} />
            </Form.Field>
          )}
        </Field>

        <Field name="phone_ext">
          {({ field, form }) => (
            <Form.Field
              grow={0.3}
              label="Ext."
              showLabel={!!field.value}
              error={form.touched[field.name] && form.errors[field.name]}
            >
              <Input {...field} placeholder="Ext." autoComplete="on" />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
    </Wrapper>
  );
};

export default ContactInformation;
