import { useContext } from 'react';

import { StoreContext } from '../Store';
import Loading from './Loading';
// import Maintenance from './Maintenance';
import RequestForQuote from './RequestForQuote';
import Success from './Success';

const Content = () => {
  const { globalState } = useContext(StoreContext);

  // if (!globalState.isApiHealthy && globalState.isApiHealthy !== undefined) {
  //   return <Maintenance />;
  // }
  switch (globalState.currentPage) {
    case 'loading':
      return <Loading />;
    case 'form':
      return <RequestForQuote />;
    case 'success':
      return <Success />;
    default:
      return null;
  }
};

export default Content;
