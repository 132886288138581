import { Field } from 'formik';
import moment from 'moment';
import styled from 'styled-components';

import DatePicker from '../ui/DatePicker';
import Form from '../ui/Form';

const Wrapper = styled.div``;

const Hint = styled.p`
  margin: 0;
  font-size: 14px;
  color: #4a4a4a;
`;

const RequestedDeliveryDate = () => {
  return (
    <Wrapper>
      <Hint>
        By default, quotes will reflect standard lead time based on shop
        capacity. To request delivery by a certain date, please indicate below.
      </Hint>
      <Field name="requested_delivery_date">
        {({ field, form }) => (
          <Form.Field
            label="Date (MM/DD/YY)"
            showLabel={!!field.value}
            error={form.touched[field.name] && form.errors[field.name]}
          >
            <DatePicker
              {...field}
              placeholder="Date (MM/DD/YY)"
              format="MM/DD/YY"
              numberOfMonths={1}
              value={field.value && moment(field.value)}
              onChange={(value) =>
                form.setFieldValue(
                  'requested_delivery_date',
                  value && value.format('YYYY-MM-DD'),
                )
              }
            />
          </Form.Field>
        )}
      </Field>
    </Wrapper>
  );
};

export default RequestedDeliveryDate;
