import t from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

const StyledInput = styled(ReactInputMask)`
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 2px;
  background-color: #ffffff;
  outline: none;
  padding: 1em 0.85em;

  font-family: inherit;
  font-size: ${(props) => props.size}px;

  &::placeholder {
    color: #747474;
  }

  &:hover:not([disabled]) {
    border-color: #aaaaaa;
  }

  &:focus:not([disabled]) {
    border-color: #828282;
  }

  &[disabled] {
    background-color: #f4f4f4;
    ${(props) =>
      props.error &&
      `
      border-color: #D33131;
    `};
  }

  ${(props) =>
    props.error &&
    `
    &, &:hover:not([disabled]), &:focus:not([disabled]) {
      border-color: #D33131;
      background-color: rgba(255, 91, 93, 0.02);
    }
  `};
`;

const Message = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 2px;
  ${(props) =>
    props.error &&
    `
    color: #D33131;
  `};
`;

class TextInput extends React.Component {
  input = null;

  static propTypes = {
    autoFocus: t.bool,
    size: t.number,
    className: t.string,
    inputClassName: t.string,
    error: t.oneOfType([t.string, t.bool]),
    hint: t.string,
  };

  static defaultProps = {
    autoFocus: false,
    size: 14,
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.input.focus();
    }
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  render() {
    const { className, inputClassName, error, hint, size, ...props } =
      this.props;
    return (
      <Wrapper className={className}>
        <StyledInput
          className={inputClassName}
          ref={(ref) => (this.input = ref)}
          error={error}
          size={size}
          {...props}
        />
        {hint && <Message>{hint}</Message>}
        {error && typeof error === 'string' && (
          <Message error role="alert">
            {error}
          </Message>
        )}
      </Wrapper>
    );
  }
}

export default styled(TextInput)``;
