import { Field } from 'formik';
import styled from 'styled-components';

import Form from '../ui/Form';
import Input from '../ui/TextInput';

const Wrapper = styled.div``;

const Referrer = () => {
  return (
    <Wrapper>
      <Field name="referrer">
        {({ form, field }) => (
          <Form.Field
            error={form.touched[field.name] && form.errors[field.name]}
          >
            <Input
              {...field}
              placeholder="Tell us how you found us (e.g. email, a colleague, Google search, etc.)"
            />
          </Form.Field>
        )}
      </Field>
    </Wrapper>
  );
};

export default Referrer;
