export const COUNTRIES = [
  { name: 'United States', abbreviation: 'USA' },
  { name: 'Canada', abbreviation: 'CAN' },
  { name: 'Afghanistan', abbreviation: 'AFG' },
  { name: 'Åland Islands', abbreviation: 'ALA' },
  { name: 'Albania', abbreviation: 'ALB' },
  { name: 'Algeria', abbreviation: 'DZA' },
  { name: 'American Samoa', abbreviation: 'ASM' },
  { name: 'Andorra', abbreviation: 'AND' },
  { name: 'Angola', abbreviation: 'AGO' },
  { name: 'Anguilla', abbreviation: 'AIA' },
  { name: 'Antarctica', abbreviation: 'ATA' },
  { name: 'Antigua and Barbuda', abbreviation: 'ATG' },
  { name: 'Argentina', abbreviation: 'ARG' },
  { name: 'Armenia', abbreviation: 'ARM' },
  { name: 'Aruba', abbreviation: 'ABW' },
  { name: 'Australia', abbreviation: 'AUS' },
  { name: 'Austria', abbreviation: 'AUT' },
  { name: 'Azerbaijan', abbreviation: 'AZE' },
  { name: 'Bahamas', abbreviation: 'BHS' },
  { name: 'Bahrain', abbreviation: 'BHR' },
  { name: 'Bangladesh', abbreviation: 'BGD' },
  { name: 'Barbados', abbreviation: 'BRB' },
  { name: 'Belarus', abbreviation: 'BLR' },
  { name: 'Belgium', abbreviation: 'BEL' },
  { name: 'Belize', abbreviation: 'BLZ' },
  { name: 'Benin', abbreviation: 'BEN' },
  { name: 'Bermuda', abbreviation: 'BMU' },
  { name: 'Bhutan', abbreviation: 'BTN' },
  { name: 'Bolivia, Plurinational State of', abbreviation: 'BOL' },
  { name: 'Bonaire, Sint Eustatius and Saba', abbreviation: 'BES' },
  { name: 'Bosnia and Herzegovina', abbreviation: 'BIH' },
  { name: 'Botswana', abbreviation: 'BWA' },
  { name: 'Bouvet Island', abbreviation: 'BVT' },
  { name: 'Brazil', abbreviation: 'BRA' },
  { name: 'British Indian Ocean Territory', abbreviation: 'IOT' },
  { name: 'Brunei Darussalam', abbreviation: 'BRN' },
  { name: 'Bulgaria', abbreviation: 'BGR' },
  { name: 'Burkina Faso', abbreviation: 'BFA' },
  { name: 'Burundi', abbreviation: 'BDI' },
  { name: 'Cabo Verde', abbreviation: 'CPV' },
  { name: 'Cambodia', abbreviation: 'KHM' },
  { name: 'Cameroon', abbreviation: 'CMR' },
  { name: 'Canada', abbreviation: 'CAN' },
  { name: 'Cayman Islands', abbreviation: 'CYM' },
  { name: 'Central African Republic', abbreviation: 'CAF' },
  { name: 'Chad', abbreviation: 'TCD' },
  { name: 'Chile', abbreviation: 'CHL' },
  { name: 'China', abbreviation: 'CHN' },
  { name: 'Christmas Island', abbreviation: 'CXR' },
  { name: 'Cocos (Keeling) Islands', abbreviation: 'CCK' },
  { name: 'Colombia', abbreviation: 'COL' },
  { name: 'Comoros', abbreviation: 'COM' },
  { name: 'Congo, The Democratic Republic of the', abbreviation: 'COD' },
  { name: 'Congo', abbreviation: 'COG' },
  { name: 'Cook Islands', abbreviation: 'COK' },
  { name: 'Costa Rica', abbreviation: 'CRI' },
  { name: "Côte d'Ivoire", abbreviation: 'CIV' },
  { name: 'Croatia', abbreviation: 'HRV' },
  { name: 'Cuba', abbreviation: 'CUB' },
  { name: 'Curaçao', abbreviation: 'CUW' },
  { name: 'Cyprus', abbreviation: 'CYP' },
  { name: 'Czechia', abbreviation: 'CZE' },
  { name: 'Denmark', abbreviation: 'DNK' },
  { name: 'Djibouti', abbreviation: 'DJI' },
  { name: 'Dominica', abbreviation: 'DMA' },
  { name: 'Dominican Republic', abbreviation: 'DOM' },
  { name: 'Ecuador', abbreviation: 'ECU' },
  { name: 'Egypt', abbreviation: 'EGY' },
  { name: 'El Salvador', abbreviation: 'SLV' },
  { name: 'Equatorial Guinea', abbreviation: 'GNQ' },
  { name: 'Eritrea', abbreviation: 'ERI' },
  { name: 'Estonia', abbreviation: 'EST' },
  { name: 'Eswatini', abbreviation: 'SWZ' },
  { name: 'Ethiopia', abbreviation: 'ETH' },
  { name: 'Falkland Islands (Malvinas)', abbreviation: 'FLK' },
  { name: 'Faroe Islands', abbreviation: 'FRO' },
  { name: 'Fiji', abbreviation: 'FJI' },
  { name: 'Finland', abbreviation: 'FIN' },
  { name: 'France', abbreviation: 'FRA' },
  { name: 'French Guiana', abbreviation: 'GUF' },
  { name: 'French Polynesia', abbreviation: 'PYF' },
  { name: 'French Southern Territories', abbreviation: 'ATF' },
  { name: 'Gabon', abbreviation: 'GAB' },
  { name: 'Gambia', abbreviation: 'GMB' },
  { name: 'Georgia', abbreviation: 'GEO' },
  { name: 'Germany', abbreviation: 'DEU' },
  { name: 'Ghana', abbreviation: 'GHA' },
  { name: 'Gibraltar', abbreviation: 'GIB' },
  { name: 'Greece', abbreviation: 'GRC' },
  { name: 'Greenland', abbreviation: 'GRL' },
  { name: 'Grenada', abbreviation: 'GRD' },
  { name: 'Guadeloupe', abbreviation: 'GLP' },
  { name: 'Guam', abbreviation: 'GUM' },
  { name: 'Guatemala', abbreviation: 'GTM' },
  { name: 'Guernsey', abbreviation: 'GGY' },
  { name: 'Guinea-Bissau', abbreviation: 'GNB' },
  { name: 'Guinea', abbreviation: 'GIN' },
  { name: 'Guyana', abbreviation: 'GUY' },
  { name: 'Haiti', abbreviation: 'HTI' },
  { name: 'Heard Island and McDonald Islands', abbreviation: 'HMD' },
  { name: 'Holy See (Vatican City State)', abbreviation: 'VAT' },
  { name: 'Honduras', abbreviation: 'HND' },
  { name: 'Hong Kong', abbreviation: 'HKG' },
  { name: 'Hungary', abbreviation: 'HUN' },
  { name: 'Iceland', abbreviation: 'ISL' },
  { name: 'India', abbreviation: 'IND' },
  { name: 'Indonesia', abbreviation: 'IDN' },
  { name: 'Iran, Islamic Republic of', abbreviation: 'IRN' },
  { name: 'Iraq', abbreviation: 'IRQ' },
  { name: 'Ireland', abbreviation: 'IRL' },
  { name: 'Isle of Man', abbreviation: 'IMN' },
  { name: 'Israel', abbreviation: 'ISR' },
  { name: 'Italy', abbreviation: 'ITA' },
  { name: 'Jamaica', abbreviation: 'JAM' },
  { name: 'Japan', abbreviation: 'JPN' },
  { name: 'Jersey', abbreviation: 'JEY' },
  { name: 'Jordan', abbreviation: 'JOR' },
  { name: 'Kazakhstan', abbreviation: 'KAZ' },
  { name: 'Kenya', abbreviation: 'KEN' },
  { name: 'Kiribati', abbreviation: 'KIR' },
  { name: "Korea, Democratic People's Republic of", abbreviation: 'PRK' },
  { name: 'Korea, Republic of', abbreviation: 'KOR' },
  { name: 'Kuwait', abbreviation: 'KWT' },
  { name: 'Kyrgyzstan', abbreviation: 'KGZ' },
  { name: "Lao People's Democratic Republic", abbreviation: 'LAO' },
  { name: 'Latvia', abbreviation: 'LVA' },
  { name: 'Lebanon', abbreviation: 'LBN' },
  { name: 'Lesotho', abbreviation: 'LSO' },
  { name: 'Liberia', abbreviation: 'LBR' },
  { name: 'Libya', abbreviation: 'LBY' },
  { name: 'Liechtenstein', abbreviation: 'LIE' },
  { name: 'Lithuania', abbreviation: 'LTU' },
  { name: 'Luxembourg', abbreviation: 'LUX' },
  { name: 'Macao', abbreviation: 'MAC' },
  { name: 'Madagascar', abbreviation: 'MDG' },
  { name: 'Malawi', abbreviation: 'MWI' },
  { name: 'Malaysia', abbreviation: 'MYS' },
  { name: 'Maldives', abbreviation: 'MDV' },
  { name: 'Mali', abbreviation: 'MLI' },
  { name: 'Malta', abbreviation: 'MLT' },
  { name: 'Marshall Islands', abbreviation: 'MHL' },
  { name: 'Martinique', abbreviation: 'MTQ' },
  { name: 'Mauritania', abbreviation: 'MRT' },
  { name: 'Mauritius', abbreviation: 'MUS' },
  { name: 'Mayotte', abbreviation: 'MYT' },
  { name: 'Mexico', abbreviation: 'MEX' },
  { name: 'Micronesia, Federated States of', abbreviation: 'FSM' },
  { name: 'Moldova, Republic of', abbreviation: 'MDA' },
  { name: 'Monaco', abbreviation: 'MCO' },
  { name: 'Mongolia', abbreviation: 'MNG' },
  { name: 'Montenegro', abbreviation: 'MNE' },
  { name: 'Montserrat', abbreviation: 'MSR' },
  { name: 'Morocco', abbreviation: 'MAR' },
  { name: 'Mozambique', abbreviation: 'MOZ' },
  { name: 'Myanmar', abbreviation: 'MMR' },
  { name: 'Namibia', abbreviation: 'NAM' },
  { name: 'Nauru', abbreviation: 'NRU' },
  { name: 'Nepal', abbreviation: 'NPL' },
  { name: 'Netherlands', abbreviation: 'NLD' },
  { name: 'New Caledonia', abbreviation: 'NCL' },
  { name: 'New Zealand', abbreviation: 'NZL' },
  { name: 'Nicaragua', abbreviation: 'NIC' },
  { name: 'Niger', abbreviation: 'NER' },
  { name: 'Nigeria', abbreviation: 'NGA' },
  { name: 'Niue', abbreviation: 'NIU' },
  { name: 'Norfolk Island', abbreviation: 'NFK' },
  { name: 'North Macedonia', abbreviation: 'MKD' },
  { name: 'Northern Mariana Islands', abbreviation: 'MNP' },
  { name: 'Norway', abbreviation: 'NOR' },
  { name: 'Oman', abbreviation: 'OMN' },
  { name: 'Pakistan', abbreviation: 'PAK' },
  { name: 'Palau', abbreviation: 'PLW' },
  { name: 'Palestine, State of', abbreviation: 'PSE' },
  { name: 'Panama', abbreviation: 'PAN' },
  { name: 'Papua New Guinea', abbreviation: 'PNG' },
  { name: 'Paraguay', abbreviation: 'PRY' },
  { name: 'Peru', abbreviation: 'PER' },
  { name: 'Philippines', abbreviation: 'PHL' },
  { name: 'Pitcairn', abbreviation: 'PCN' },
  { name: 'Poland', abbreviation: 'POL' },
  { name: 'Portugal', abbreviation: 'PRT' },
  { name: 'Puerto Rico', abbreviation: 'PRI' },
  { name: 'Qatar', abbreviation: 'QAT' },
  { name: 'Réunion', abbreviation: 'REU' },
  { name: 'Romania', abbreviation: 'ROU' },
  { name: 'Russian Federation', abbreviation: 'RUS' },
  { name: 'Rwanda', abbreviation: 'RWA' },
  { name: 'Saint Barthélemy', abbreviation: 'BLM' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', abbreviation: 'SHN' },
  { name: 'Saint Kitts and Nevis', abbreviation: 'KNA' },
  { name: 'Saint Lucia', abbreviation: 'LCA' },
  { name: 'Saint Martin (French part)', abbreviation: 'MAF' },
  { name: 'Saint Pierre and Miquelon', abbreviation: 'SPM' },
  { name: 'Saint Vincent and the Grenadines', abbreviation: 'VCT' },
  { name: 'Samoa', abbreviation: 'WSM' },
  { name: 'San Marino', abbreviation: 'SMR' },
  { name: 'Sao Tome and Principe', abbreviation: 'STP' },
  { name: 'Saudi Arabia', abbreviation: 'SAU' },
  { name: 'Senegal', abbreviation: 'SEN' },
  { name: 'Serbia', abbreviation: 'SRB' },
  { name: 'Seychelles', abbreviation: 'SYC' },
  { name: 'Sierra Leone', abbreviation: 'SLE' },
  { name: 'Singapore', abbreviation: 'SGP' },
  { name: 'Sint Maarten (Dutch part)', abbreviation: 'SXM' },
  { name: 'Slovakia', abbreviation: 'SVK' },
  { name: 'Slovenia', abbreviation: 'SVN' },
  { name: 'Solomon Islands', abbreviation: 'SLB' },
  { name: 'Somalia', abbreviation: 'SOM' },
  { name: 'South Africa', abbreviation: 'ZAF' },
  { name: 'South Georgia and the South Sandwich Islands', abbreviation: 'SGS' },
  { name: 'South Sudan', abbreviation: 'SSD' },
  { name: 'Spain', abbreviation: 'ESP' },
  { name: 'Sri Lanka', abbreviation: 'LKA' },
  { name: 'Sudan', abbreviation: 'SDN' },
  { name: 'Suriname', abbreviation: 'SUR' },
  { name: 'Svalbard and Jan Mayen', abbreviation: 'SJM' },
  { name: 'Sweden', abbreviation: 'SWE' },
  { name: 'Switzerland', abbreviation: 'CHE' },
  { name: 'Syrian Arab Republic', abbreviation: 'SYR' },
  { name: 'Taiwan, Province of China', abbreviation: 'TWN' },
  { name: 'Tajikistan', abbreviation: 'TJK' },
  { name: 'Tanzania, United Republic of', abbreviation: 'TZA' },
  { name: 'Thailand', abbreviation: 'THA' },
  { name: 'Timor-Leste', abbreviation: 'TLS' },
  { name: 'Togo', abbreviation: 'TGO' },
  { name: 'Tokelau', abbreviation: 'TKL' },
  { name: 'Tonga', abbreviation: 'TON' },
  { name: 'Trinidad and Tobago', abbreviation: 'TTO' },
  { name: 'Tunisia', abbreviation: 'TUN' },
  { name: 'Turkey', abbreviation: 'TUR' },
  { name: 'Turkmenistan', abbreviation: 'TKM' },
  { name: 'Turks and Caicos Islands', abbreviation: 'TCA' },
  { name: 'Tuvalu', abbreviation: 'TUV' },
  { name: 'Uganda', abbreviation: 'UGA' },
  { name: 'Ukraine', abbreviation: 'UKR' },
  { name: 'United Arab Emirates', abbreviation: 'ARE' },
  { name: 'United Kingdom', abbreviation: 'GBR' },
  { name: 'United States Minor Outlying Islands', abbreviation: 'UMI' },
  { name: 'United States', abbreviation: 'USA' },
  { name: 'Uruguay', abbreviation: 'URY' },
  { name: 'Uzbekistan', abbreviation: 'UZB' },
  { name: 'Vanuatu', abbreviation: 'VUT' },
  { name: 'Venezuela, Bolivarian Republic of', abbreviation: 'VEN' },
  { name: 'Viet Nam', abbreviation: 'VNM' },
  { name: 'Virgin Islands, British', abbreviation: 'VGB' },
  { name: 'Virgin Islands, U.S.', abbreviation: 'VIR' },
  { name: 'Wallis and Futuna', abbreviation: 'WLF' },
  { name: 'Western Sahara', abbreviation: 'ESH' },
  { name: 'Yemen', abbreviation: 'YEM' },
  { name: 'Zambia', abbreviation: 'ZMB' },
  { name: 'Zimbabwe', abbreviation: 'ZWE' },
];

export const COUNTRY_REGIONS = {
  AUS: {
    postalCodeLabel: 'Postcode',
    regionLabel: 'State',
    regions: [
      { name: 'Australian Capital Territory', abbreviation: 'ACT' },
      { name: 'New South Wales', abbreviation: 'NSW' },
      { name: 'Northern Territory', abbreviation: 'NT' },
      { name: 'Queensland', abbreviation: 'QLD' },
      { name: 'South Australia', abbreviation: 'SA' },
      { name: 'Tasmania', abbreviation: 'TAS' },
      { name: 'Victoria', abbreviation: 'VIC' },
      { name: 'Western Australia', abbreviation: 'WA' },
    ],
  },
  BRA: {
    postalCodeLabel: 'Postal Code',
    regionLabel: 'State',
    regions: [
      { name: 'Acre', abbreviation: 'AC' },
      { name: 'Alagoas', abbreviation: 'AL' },
      { name: 'Amapá', abbreviation: 'AP' },
      { name: 'Amazonas', abbreviation: 'AM' },
      { name: 'Bahia', abbreviation: 'BA' },
      { name: 'Ceará', abbreviation: 'CE' },
      { name: 'Distrito Federal', abbreviation: 'DF' },
      { name: 'Espírito Santo', abbreviation: 'ES' },
      { name: 'Fernando de Noronha', abbreviation: 'FN' },
      { name: 'Goiás', abbreviation: 'GO' },
      { name: 'Maranhão', abbreviation: 'MA' },
      { name: 'Mato Grosso do Sul', abbreviation: 'MS' },
      { name: 'Mato Grosso', abbreviation: 'MT' },
      { name: 'Minas Gerais', abbreviation: 'MG' },
      { name: 'Pará', abbreviation: 'PA' },
      { name: 'Paraíba', abbreviation: 'PB' },
      { name: 'Paraná', abbreviation: 'PR' },
      { name: 'Pernambuco', abbreviation: 'PE' },
      { name: 'Piauí', abbreviation: 'PI' },
      { name: 'Rio de Janeiro', abbreviation: 'RJ' },
      { name: 'Rio Grande do Norte', abbreviation: 'RN' },
      { name: 'Rio Grande do Sul', abbreviation: 'RS' },
      { name: 'Rondônia', abbreviation: 'RO' },
      { name: 'Roraima', abbreviation: 'RR' },
      { name: 'Santa Catarina', abbreviation: 'SC' },
      { name: 'São Paulo', abbreviation: 'SP' },
      { name: 'Sergipe', abbreviation: 'SE' },
      { name: 'Tocantins', abbreviation: 'TO' },
    ],
  },
  CAN: {
    postalCodeLabel: 'Postal Code',
    regionLabel: 'Province',
    regions: [
      {
        name: 'Alberta',
        abbreviation: 'AB',
      },
      {
        name: 'British Columbia',
        abbreviation: 'BC',
      },
      {
        name: 'Manitoba',
        abbreviation: 'MB',
      },
      {
        name: 'New Brunswick',
        abbreviation: 'NB',
      },
      {
        name: 'Newfoundland and Labrador',
        abbreviation: 'NL',
      },
      {
        name: 'Nova Scotia',
        abbreviation: 'NS',
      },
      {
        name: 'Ontario',
        abbreviation: 'ON',
      },
      {
        name: 'Prince Edward Island',
        abbreviation: 'PE',
      },
      {
        name: 'Quebec',
        abbreviation: 'QC',
      },
      {
        name: 'Saskatchewan',
        abbreviation: 'SK',
      },
    ],
  },
  CHN: {
    postalCodeLabel: 'Postal Code',
    regionLabel: 'Province',
    regions: [
      { name: 'Anhui Sheng', abbreviation: 'AH' },
      { name: 'Beijing Shi', abbreviation: 'BJ' },
      { name: 'Chongqing Shi', abbreviation: 'CQ' },
      { name: 'Fujian Sheng', abbreviation: 'FJ' },
      { name: 'Gansu Sheng', abbreviation: 'GS' },
      { name: 'Guangdong Sheng', abbreviation: 'GD' },
      { name: 'Guangxi Zhuangzu Zizhiqu', abbreviation: 'GX' },
      { name: 'Guizhou Sheng', abbreviation: 'GZ' },
      { name: 'Hainan Sheng', abbreviation: 'HI' },
      { name: 'Hebei Sheng', abbreviation: 'HE' },
      { name: 'Heilongjiang Sheng', abbreviation: 'HL' },
      { name: 'Henan Sheng', abbreviation: 'HA' },
      { name: 'Hong Kong SAR', abbreviation: 'HK' },
      { name: 'Hubei Sheng', abbreviation: 'HB' },
      { name: 'Hunan Sheng', abbreviation: 'HN' },
      { name: 'Jiangsu Sheng', abbreviation: 'JS' },
      { name: 'Jiangxi Sheng', abbreviation: 'JX' },
      { name: 'Jilin Sheng', abbreviation: 'JL' },
      { name: 'Liaoning Sheng', abbreviation: 'LN' },
      { name: 'Macao SAR', abbreviation: 'MO' },
      { name: 'Nei Mongol Zizhiqu', abbreviation: 'NM' },
      { name: 'Ningxia Huizi Zizhiqu', abbreviation: 'NX' },
      { name: 'Qinghai Sheng', abbreviation: 'QH' },
      { name: 'Shaanxi Sheng', abbreviation: 'SN' },
      { name: 'Shandong Sheng', abbreviation: 'SD' },
      { name: 'Shanghai Shi', abbreviation: 'SH' },
      { name: 'Shanxi Sheng', abbreviation: 'SX' },
      { name: 'Sichuan Sheng', abbreviation: 'SC' },
      { name: 'Taiwan Sheng', abbreviation: 'TW' },
      { name: 'Tianjin Shi', abbreviation: 'TJ' },
      { name: 'Xinjiang Uygur Zizhiqu', abbreviation: 'XJ' },
      { name: 'Xizang Zizhiqu', abbreviation: 'XZ' },
      { name: 'Yunnan Sheng', abbreviation: 'YN' },
      { name: 'Zhejiang Sheng', abbreviation: 'ZJ' },
    ],
  },
  IND: {
    postalCodeLabel: 'PIN',
    regionLabel: 'State',
    regions: [
      { name: 'Andaman and Nicobar Islands', abbreviation: 'AN' },
      { name: 'Andhra Pradesh', abbreviation: 'AP' },
      { name: 'Arunachal Pradesh', abbreviation: 'AR' },
      { name: 'Assam', abbreviation: 'AS' },
      { name: 'Bihar', abbreviation: 'BR' },
      { name: 'Chandigarh', abbreviation: 'CH' },
      { name: 'Chhattisgarh', abbreviation: 'CT' },
      { name: 'Dadra and Nagar Haveli', abbreviation: 'DN' },
      { name: 'Daman and Diu', abbreviation: 'DD' },
      { name: 'Delhi', abbreviation: 'DL' },
      { name: 'Goa', abbreviation: 'GA' },
      { name: 'Gujarat', abbreviation: 'GJ' },
      { name: 'Haryana', abbreviation: 'HR' },
      { name: 'Himachal Pradesh', abbreviation: 'HP' },
      { name: 'Jammu and Kashmir', abbreviation: 'JK' },
      { name: 'Jharkhand', abbreviation: 'JH' },
      { name: 'Karnataka', abbreviation: 'KA' },
      { name: 'Kerala', abbreviation: 'KL' },
      { name: 'Lakshadweep', abbreviation: 'LD' },
      { name: 'Madhya Pradesh', abbreviation: 'MP' },
      { name: 'Maharashtra', abbreviation: 'MH' },
      { name: 'Manipur', abbreviation: 'MN' },
      { name: 'Meghalaya', abbreviation: 'ML' },
      { name: 'Mizoram', abbreviation: 'MZ' },
      { name: 'Nagaland', abbreviation: 'NL' },
      { name: 'Odisha', abbreviation: 'OR' },
      { name: 'Puducherry', abbreviation: 'PY' },
      { name: 'Punjab', abbreviation: 'PB' },
      { name: 'Rajasthan', abbreviation: 'RJ' },
      { name: 'Sikkim', abbreviation: 'SK' },
      { name: 'Tamil Nadu', abbreviation: 'TN' },
      { name: 'Telangana', abbreviation: 'TG' },
      { name: 'Tripura', abbreviation: 'TR' },
      { name: 'Uttar Pradesh', abbreviation: 'UP' },
      { name: 'Uttarakhand', abbreviation: 'UT' },
      { name: 'West Bengal', abbreviation: 'WB' },
    ],
  },
  IRL: {
    postalCodeLabel: 'Eircode',
    regionLabel: 'County',
    regions: [
      { name: 'Carlow', abbreviation: 'CW' },
      { name: 'Cavan', abbreviation: 'CN' },
      { name: 'Clare', abbreviation: 'CE' },
      { name: 'Connacht', abbreviation: 'C' },
      { name: 'Cork', abbreviation: 'CO' },
      { name: 'Donegal', abbreviation: 'DL' },
      { name: 'Dublin', abbreviation: 'D' },
      { name: 'Galway', abbreviation: 'G' },
      { name: 'Kerry', abbreviation: 'KY' },
      { name: 'Kildare', abbreviation: 'KE' },
      { name: 'Kilkenny', abbreviation: 'KK' },
      { name: 'Laois', abbreviation: 'LS' },
      { name: 'Leinster', abbreviation: 'L' },
      { name: 'Leitrim', abbreviation: 'LM' },
      { name: 'Limerick', abbreviation: 'LK' },
      { name: 'Longford', abbreviation: 'LD' },
      { name: 'Louth', abbreviation: 'LH' },
      { name: 'Mayo', abbreviation: 'MO' },
      { name: 'Meath', abbreviation: 'MH' },
      { name: 'Monaghan', abbreviation: 'MN' },
      { name: 'Munster', abbreviation: 'M' },
      { name: 'Offaly', abbreviation: 'OY' },
      { name: 'Roscommon', abbreviation: 'RN' },
      { name: 'Sligo', abbreviation: 'SO' },
      { name: 'Tipperary', abbreviation: 'TA' },
      { name: 'Ulster', abbreviation: 'U' },
      { name: 'Waterford', abbreviation: 'WD' },
      { name: 'Westmeath', abbreviation: 'WH' },
      { name: 'Wexford', abbreviation: 'WX' },
      { name: 'Wicklow', abbreviation: 'WW' },
    ],
  },
  ITA: {
    postalCodeLabel: 'Postal Code',
    regionLabel: 'Province',
    regions: [
      { name: 'Abruzzo', abbreviation: '65' },
      { name: 'Agrigento', abbreviation: 'AG' },
      { name: 'Alessandria', abbreviation: 'AL' },
      { name: 'Ancona', abbreviation: 'AN' },
      { name: 'Aosta', abbreviation: 'AO' },
      { name: 'Arezzo', abbreviation: 'AR' },
      { name: 'Ascoli Piceno', abbreviation: 'AP' },
      { name: 'Asti', abbreviation: 'AT' },
      { name: 'Avellino', abbreviation: 'AV' },
      { name: 'Bari', abbreviation: 'BA' },
      { name: 'Barletta-Andria-Trani', abbreviation: 'BT' },
      { name: 'Basilicata', abbreviation: '77' },
      { name: 'Belluno', abbreviation: 'BL' },
      { name: 'Benevento', abbreviation: 'BN' },
      { name: 'Bergamo', abbreviation: 'BG' },
      { name: 'Biella', abbreviation: 'BI' },
      { name: 'Bologna', abbreviation: 'BO' },
      { name: 'Bolzano', abbreviation: 'BZ' },
      { name: 'Brescia', abbreviation: 'BS' },
      { name: 'Brindisi', abbreviation: 'BR' },
      { name: 'Cagliari', abbreviation: 'CA' },
      { name: 'Calabria', abbreviation: '78' },
      { name: 'Caltanissetta', abbreviation: 'CL' },
      { name: 'Campania', abbreviation: '72' },
      { name: 'Campobasso', abbreviation: 'CB' },
      { name: 'Carbonia-Iglesias', abbreviation: 'CI' },
      { name: 'Caserta', abbreviation: 'CE' },
      { name: 'Catania', abbreviation: 'CT' },
      { name: 'Catanzaro', abbreviation: 'CZ' },
      { name: 'Chieti', abbreviation: 'CH' },
      { name: 'Como', abbreviation: 'CO' },
      { name: 'Cosenza', abbreviation: 'CS' },
      { name: 'Cremona', abbreviation: 'CR' },
      { name: 'Crotone', abbreviation: 'KR' },
      { name: 'Cuneo', abbreviation: 'CN' },
      { name: 'Emilia-Romagna', abbreviation: '45' },
      { name: 'Enna', abbreviation: 'EN' },
      { name: 'Fermo', abbreviation: 'FM' },
      { name: 'Ferrara', abbreviation: 'FE' },
      { name: 'Firenze', abbreviation: 'FI' },
      { name: 'Foggia', abbreviation: 'FG' },
      { name: 'Forlì-Cesena', abbreviation: 'FC' },
      { name: 'Friuli-Venezia Giulia', abbreviation: '36' },
      { name: 'Frosinone', abbreviation: 'FR' },
      { name: 'Genova', abbreviation: 'GE' },
      { name: 'Gorizia', abbreviation: 'GO' },
      { name: 'Grosseto', abbreviation: 'GR' },
      { name: 'Imperia', abbreviation: 'IM' },
      { name: 'Isernia', abbreviation: 'IS' },
      { name: "L'Aquila", abbreviation: 'AQ' },
      { name: 'La Spezia', abbreviation: 'SP' },
      { name: 'Latina', abbreviation: 'LT' },
      { name: 'Lazio', abbreviation: '62' },
      { name: 'Lecce', abbreviation: 'LE' },
      { name: 'Lecco', abbreviation: 'LC' },
      { name: 'Liguria', abbreviation: '42' },
      { name: 'Livorno', abbreviation: 'LI' },
      { name: 'Lodi', abbreviation: 'LO' },
      { name: 'Lombardia', abbreviation: '25' },
      { name: 'Lucca', abbreviation: 'LU' },
      { name: 'Macerata', abbreviation: 'MC' },
      { name: 'Mantova', abbreviation: 'MN' },
      { name: 'Marche', abbreviation: '57' },
      { name: 'Massa-Carrara', abbreviation: 'MS' },
      { name: 'Matera', abbreviation: 'MT' },
      { name: 'Medio Campidano', abbreviation: 'VS' },
      { name: 'Messina', abbreviation: 'ME' },
      { name: 'Milano', abbreviation: 'MI' },
      { name: 'Modena', abbreviation: 'MO' },
      { name: 'Molise', abbreviation: '67' },
      { name: 'Monza e Brianza', abbreviation: 'MB' },
      { name: 'Napoli', abbreviation: 'NA' },
      { name: 'Novara', abbreviation: 'NO' },
      { name: 'Nuoro', abbreviation: 'NU' },
      { name: 'Ogliastra', abbreviation: 'OG' },
      { name: 'Olbia-Tempio', abbreviation: 'OT' },
      { name: 'Oristano', abbreviation: 'OR' },
      { name: 'Padova', abbreviation: 'PD' },
      { name: 'Palermo', abbreviation: 'PA' },
      { name: 'Parma', abbreviation: 'PR' },
      { name: 'Pavia', abbreviation: 'PV' },
      { name: 'Perugia', abbreviation: 'PG' },
      { name: 'Pesaro e Urbino', abbreviation: 'PU' },
      { name: 'Pescara', abbreviation: 'PE' },
      { name: 'Piacenza', abbreviation: 'PC' },
      { name: 'Piemonte', abbreviation: '21' },
      { name: 'Pisa', abbreviation: 'PI' },
      { name: 'Pistoia', abbreviation: 'PT' },
      { name: 'Pordenone', abbreviation: 'PN' },
      { name: 'Potenza', abbreviation: 'PZ' },
      { name: 'Prato', abbreviation: 'PO' },
      { name: 'Puglia', abbreviation: '75' },
      { name: 'Ragusa', abbreviation: 'RG' },
      { name: 'Ravenna', abbreviation: 'RA' },
      { name: 'Reggio Calabria', abbreviation: 'RC' },
      { name: 'Reggio Emilia', abbreviation: 'RE' },
      { name: 'Rieti', abbreviation: 'RI' },
      { name: 'Rimini', abbreviation: 'RN' },
      { name: 'Roma', abbreviation: 'RM' },
      { name: 'Rovigo', abbreviation: 'RO' },
      { name: 'Salerno', abbreviation: 'SA' },
      { name: 'Sardegna', abbreviation: '88' },
      { name: 'Sassari', abbreviation: 'SS' },
      { name: 'Savona', abbreviation: 'SV' },
      { name: 'Sicilia', abbreviation: '82' },
      { name: 'Siena', abbreviation: 'SI' },
      { name: 'Siracusa', abbreviation: 'SR' },
      { name: 'Sondrio', abbreviation: 'SO' },
      { name: 'Taranto', abbreviation: 'TA' },
      { name: 'Teramo', abbreviation: 'TE' },
      { name: 'Terni', abbreviation: 'TR' },
      { name: 'Torino', abbreviation: 'TO' },
      { name: 'Toscana', abbreviation: '52' },
      { name: 'Trapani', abbreviation: 'TP' },
      { name: 'Trentino-Alto Adige', abbreviation: '32' },
      { name: 'Trento', abbreviation: 'TN' },
      { name: 'Treviso', abbreviation: 'TV' },
      { name: 'Trieste', abbreviation: 'TS' },
      { name: 'Udine', abbreviation: 'UD' },
      { name: 'Umbria', abbreviation: '55' },
      { name: "Valle d'Aosta", abbreviation: '23' },
      { name: 'Varese', abbreviation: 'VA' },
      { name: 'Veneto', abbreviation: '34' },
      { name: 'Venezia', abbreviation: 'VE' },
      { name: 'Verbano-Cusio-Ossola', abbreviation: 'VB' },
      { name: 'Vercelli', abbreviation: 'VC' },
      { name: 'Verona', abbreviation: 'VR' },
      { name: 'Vibo Valentia', abbreviation: 'VV' },
      { name: 'Vicenza', abbreviation: 'VI' },
      { name: 'Viterbo', abbreviation: 'VT' },
    ],
  },
  MEX: {
    postalCodeLabel: 'Postal Code',
    regionLabel: 'State',
    regions: [
      { name: 'Aguascalientes', abbreviation: 'AGU' },
      { name: 'Baja California Sur', abbreviation: 'BCS' },
      { name: 'Baja California', abbreviation: 'BCN' },
      { name: 'Campeche', abbreviation: 'CAM' },
      { name: 'Chiapas', abbreviation: 'CHP' },
      { name: 'Chihuahua', abbreviation: 'CHH' },
      { name: 'Ciudad de México', abbreviation: 'CMX' },
      { name: 'Coahuila de Zaragoza', abbreviation: 'COA' },
      { name: 'Colima', abbreviation: 'COL' },
      { name: 'Durango', abbreviation: 'DUR' },
      { name: 'Guanajuato', abbreviation: 'GUA' },
      { name: 'Guerrero', abbreviation: 'GRO' },
      { name: 'Hidalgo', abbreviation: 'HID' },
      { name: 'Jalisco', abbreviation: 'JAL' },
      { name: 'México', abbreviation: 'MEX' },
      { name: 'Michoacán de Ocampo', abbreviation: 'MIC' },
      { name: 'Morelos', abbreviation: 'MOR' },
      { name: 'Nayarit', abbreviation: 'NAY' },
      { name: 'Nuevo León', abbreviation: 'NLE' },
      { name: 'Oaxaca', abbreviation: 'OAX' },
      { name: 'Puebla', abbreviation: 'PUE' },
      { name: 'Querétaro', abbreviation: 'QUE' },
      { name: 'Quintana Roo', abbreviation: 'ROO' },
      { name: 'San Luis Potosí', abbreviation: 'SLP' },
      { name: 'Sinaloa', abbreviation: 'SIN' },
      { name: 'Sonora', abbreviation: 'SON' },
      { name: 'Tabasco', abbreviation: 'TAB' },
      { name: 'Tamaulipas', abbreviation: 'TAM' },
      { name: 'Tlaxcala', abbreviation: 'TLA' },
      { name: 'Veracruz de Ignacio de la Llave', abbreviation: 'VER' },
      { name: 'Yucatán', abbreviation: 'YUC' },
      { name: 'Zacatecas', abbreviation: 'ZAC' },
    ],
  },
  MYS: {
    postalCodeLabel: 'Postcode',
    regionLabel: 'State',
    regions: [
      {
        name: 'Johor',
        abbreviation: 'JHR',
      },
      {
        name: 'Kedah',
        abbreviation: 'KDH',
      },
      {
        name: 'Kelantan',
        abbreviation: 'KTN',
      },
      {
        name: 'Kuala Lumpur',
        abbreviation: 'KUL',
      },
      {
        name: 'Labuan',
        abbreviation: 'LBN',
      },
      {
        name: 'Malacca',
        abbreviation: 'MLK',
      },
      {
        name: 'Negeri Sembilan',
        abbreviation: 'NSN',
      },
      {
        name: 'Pahang',
        abbreviation: 'PHG',
      },
      {
        name: 'Penang',
        abbreviation: 'PNG',
      },
      {
        name: 'Perak',
        abbreviation: 'PRK',
      },
      {
        name: 'Perlis',
        abbreviation: 'PLS',
      },
      {
        name: 'Putrajaya',
        abbreviation: 'PJY',
      },
      {
        name: 'Sabah',
        abbreviation: 'SBH',
      },
      {
        name: 'Sarawak',
        abbreviation: 'SWK',
      },
      {
        name: 'Selangor',
        abbreviation: 'SGR',
      },
      {
        name: 'Terengganu',
        abbreviation: 'TRG',
      },
    ],
  },
  USA: {
    postalCodeLabel: 'ZIP Code',
    regionLabel: 'State',
    regions: [
      {
        name: 'Alabama',
        abbreviation: 'AL',
        type: 'state',
      },
      {
        name: 'Alaska',
        abbreviation: 'AK',
        type: 'state',
      },
      {
        name: 'American Samoa',
        abbreviation: 'AS',
        type: 'territory',
      },
      {
        name: 'Arizona',
        abbreviation: 'AZ',
        type: 'state',
      },
      {
        name: 'Arkansas',
        abbreviation: 'AR',
        type: 'state',
      },
      {
        name: 'California',
        abbreviation: 'CA',
        type: 'state',
      },
      {
        name: 'Colorado',
        abbreviation: 'CO',
        type: 'state',
      },
      {
        name: 'Connecticut',
        abbreviation: 'CT',
        type: 'state',
      },
      {
        name: 'Delaware',
        abbreviation: 'DE',
        type: 'state',
      },
      {
        name: 'District Of Columbia',
        abbreviation: 'DC',
        type: 'territory',
      },
      {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM',
        type: 'territory',
      },
      {
        name: 'Florida',
        abbreviation: 'FL',
        type: 'state',
      },
      {
        name: 'Georgia',
        abbreviation: 'GA',
        type: 'state',
      },
      {
        name: 'Guam',
        abbreviation: 'GU',
        type: 'territory',
      },
      {
        name: 'Hawaii',
        abbreviation: 'HI',
        type: 'state',
      },
      {
        name: 'Idaho',
        abbreviation: 'ID',
        type: 'state',
      },
      {
        name: 'Illinois',
        abbreviation: 'IL',
        type: 'state',
      },
      {
        name: 'Indiana',
        abbreviation: 'IN',
        type: 'state',
      },
      {
        name: 'Iowa',
        abbreviation: 'IA',
        type: 'state',
      },
      {
        name: 'Kansas',
        abbreviation: 'KS',
        type: 'state',
      },
      {
        name: 'Kentucky',
        abbreviation: 'KY',
        type: 'state',
      },
      {
        name: 'Louisiana',
        abbreviation: 'LA',
        type: 'state',
      },
      {
        name: 'Maine',
        abbreviation: 'ME',
        type: 'state',
      },
      {
        name: 'Marshall Islands',
        abbreviation: 'MH',
        type: 'territory',
      },
      {
        name: 'Maryland',
        abbreviation: 'MD',
        type: 'state',
      },
      {
        name: 'Massachusetts',
        abbreviation: 'MA',
        type: 'state',
      },
      {
        name: 'Michigan',
        abbreviation: 'MI',
        type: 'state',
      },
      {
        name: 'Minnesota',
        abbreviation: 'MN',
        type: 'state',
      },
      {
        name: 'Mississippi',
        abbreviation: 'MS',
        type: 'state',
      },
      {
        name: 'Missouri',
        abbreviation: 'MO',
        type: 'state',
      },
      {
        name: 'Montana',
        abbreviation: 'MT',
        type: 'state',
      },
      {
        name: 'Nebraska',
        abbreviation: 'NE',
        type: 'state',
      },
      {
        name: 'Nevada',
        abbreviation: 'NV',
        type: 'state',
      },
      {
        name: 'New Hampshire',
        abbreviation: 'NH',
        type: 'state',
      },
      {
        name: 'New Jersey',
        abbreviation: 'NJ',
        type: 'state',
      },
      {
        name: 'New Mexico',
        abbreviation: 'NM',
        type: 'state',
      },
      {
        name: 'New York',
        abbreviation: 'NY',
        type: 'state',
      },
      {
        name: 'North Carolina',
        abbreviation: 'NC',
        type: 'state',
      },
      {
        name: 'North Dakota',
        abbreviation: 'ND',
        type: 'state',
      },
      {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP',
        type: 'territory',
      },
      {
        name: 'Ohio',
        abbreviation: 'OH',
        type: 'state',
      },
      {
        name: 'Oklahoma',
        abbreviation: 'OK',
        type: 'state',
      },
      {
        name: 'Oregon',
        abbreviation: 'OR',
        type: 'state',
      },
      {
        name: 'Palau',
        abbreviation: 'PW',
        type: 'territory',
      },
      {
        name: 'Pennsylvania',
        abbreviation: 'PA',
        type: 'state',
      },
      {
        name: 'Puerto Rico',
        abbreviation: 'PR',
        type: 'territory',
      },
      {
        name: 'Rhode Island',
        abbreviation: 'RI',
        type: 'state',
      },
      {
        name: 'South Carolina',
        abbreviation: 'SC',
        type: 'state',
      },
      {
        name: 'South Dakota',
        abbreviation: 'SD',
        type: 'state',
      },
      {
        name: 'Tennessee',
        abbreviation: 'TN',
        type: 'state',
      },
      {
        name: 'Texas',
        abbreviation: 'TX',
        type: 'state',
      },
      {
        name: 'Utah',
        abbreviation: 'UT',
        type: 'state',
      },
      {
        name: 'Vermont',
        abbreviation: 'VT',
        type: 'state',
      },
      {
        name: 'Virgin Islands',
        abbreviation: 'VI',
        type: 'territory',
      },
      {
        name: 'Virginia',
        abbreviation: 'VA',
        type: 'state',
      },
      {
        name: 'Washington',
        abbreviation: 'WA',
        type: 'state',
      },
      {
        name: 'West Virginia',
        abbreviation: 'WV',
        type: 'state',
      },
      {
        name: 'Wisconsin',
        abbreviation: 'WI',
        type: 'state',
      },
      {
        name: 'Wyoming',
        abbreviation: 'WY',
        type: 'state',
      },
    ],
  },
};

// Originally adopted from https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
export const COUNTRY_POSTAL_CODE_REGEX = {
  AFG: /[0-9]{4}/,
  ALA: /22\d{3}/,
  AND: /AD\d{3}/,
  ARG: /([A-HJ-NP-Z])?\d{4}([A-Z]{3})?/,
  ARM: /(37)?\d{4}/,
  ASM: /96799/,
  AUS: /\d{4}/,
  AUT: /\d{4}/,
  AZE: /\d{4}/,
  BEL: /\d{4}/,
  BGD: /\d{4}/,
  BGR: /\d{4}/,
  BHR: /((1[0-2]|[2-9])\d{2})?/,
  BIH: /\d{5}/,
  BLR: /\d{6}/,
  BMU: /[A-Z]{2}[ ]?[A-Z0-9]{2}/,
  BRA: /\d{5}[-]?\d{3}/,
  BRB: /(BB\d{5})?/,
  BRN: /[A-Z]{2}[ ]?\d{4}/,
  CAN: /[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/,
  CCK: /6799/,
  CHE: /\d{4}/,
  CHL: /\d{7}/,
  CHN: /\d{6}/,
  COK: /\d{4}/,
  CPV: /\d{4}/,
  CRI: /\d{4,5}|\d{3}-\d{4}/,
  CXR: /6798/,
  CYP: /\d{4}/,
  CZE: /\d{3}[ ]?\d{2}/,
  DEU: /\d{5}/,
  DNK: /\d{4}/,
  DOM: /\d{5}/,
  DZA: /\d{5}/,
  ECU: /([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?/,
  EGY: /\d{5}/,
  ESP: /\d{5}/,
  EST: /\d{5}/,
  ETH: /\d{4}/,
  FIN: /\d{5}/,
  FLK: /FIQQ 1ZZ/,
  FRA: /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/,
  FRO: /\d{3}/,
  FSM: /(9694[1-4])([ -]\d{4})?/,
  GBR: /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/,
  GEO: /\d{4}/,
  GGY: /GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/,
  GIN: /\d{3}/,
  GLP: /9[78][01]\d{2}/,
  GNB: /\d{4}/,
  GRC: /\d{3}[ ]?\d{2}/,
  GRL: /39\d{2}/,
  GTM: /\d{5}/,
  GUF: /9[78]3\d{2}/,
  GUM: /969[123]\d([ -]\d{4})?/,
  HMD: /\d{4}/,
  HND: /(?:\d{5})?/,
  HRV: /\d{5}/,
  HTI: /\d{4}/,
  HUN: /\d{4}/,
  IDN: /\d{5}/,
  IMN: /IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/,
  IND: /^(?!0{1})\d{6}$/,
  IOT: /BBND 1ZZ/,
  IRQ: /\d{5}/,
  ISL: /\d{3}/,
  ISR: /\d{5}/,
  ITA: /\d{5}/,
  JEY: /JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/,
  JOR: /\d{5}/,
  JPN: /\d{3}-\d{4}/,
  KAZ: /\d{6}/,
  KEN: /\d{5}/,
  KGZ: /\d{6}/,
  KHM: /\d{5}/,
  KOR: /\d{3}[-]\d{3}/,
  KWT: /\d{5}/,
  LAO: /\d{5}/,
  LBN: /(\d{4}([ ]?\d{4})?)?/,
  LBR: /\d{4}/,
  LIE: /(948[5-9])|(949[0-7])/,
  LKA: /\d{5}/,
  LSO: /\d{3}/,
  LTU: /\d{5}/,
  LUX: /\d{4}/,
  LVA: /\d{4}/,
  MAR: /\d{5}/,
  MCO: /980\d{2}/,
  MDA: /\d{4}/,
  MDG: /\d{3}/,
  MDV: /\d{5}/,
  MEX: /\d{5}/,
  MHL: /969[67]\d([ -]\d{4})?/,
  MKD: /\d{4}/,
  MLT: /[A-Z]{3}[ ]?\d{2,4}/,
  MNE: /8\d{4}/,
  MNG: /\d{6}/,
  MNP: /9695[012]([ -]\d{4})?/,
  MTQ: /9[78]2\d{2}/,
  MUS: /(\d{3}[A-Z]{2}\d{3})?/,
  MYS: /\d{5}/,
  MYT: /976\d{2}/,
  NCL: /988\d{2}/,
  NER: /\d{4}/,
  NFK: /2899/,
  NGA: /(\d{6})?/,
  NIC: /((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?/,
  NLD: /\d{4}[ ]?[A-Z]{2}/,
  NOR: /\d{4}/,
  NPL: /\d{5}/,
  NZL: /\d{4}/,
  OMN: /(PC )?\d{3}/,
  PAK: /\d{5}/,
  PCN: /PCRN 1ZZ/,
  PFY: /987\d{2}/,
  PHL: /\d{4}/,
  PLW: /96940/,
  PNG: /\d{3}/,
  POL: /\d{2}-\d{3}/,
  PRI: /00[679]\d{2}([ -]\d{4})?/,
  PRT: /\d{4}([-]\d{3})?/,
  PRY: /\d{4}/,
  REU: /9[78]4\d{2}/,
  ROU: /\d{6}/,
  RUS: /\d{6}/,
  SAU: /\d{5}/,
  SEN: /\d{5}/,
  SGP: /\d{6}/,
  SGS: /SIQQ 1ZZ/,
  SHN: /(ASCN|STHL) 1ZZ/,
  SJM: /\d{4}/,
  SMR: /4789\d/,
  SOM: /\d{5}/,
  SPM: /9[78]5\d{2}/,
  SRB: /\d{6}/,
  SVK: /\d{3}[ ]?\d{2}/,
  SVN: /\d{4}/,
  SWE: /\d{3}[ ]?\d{2}/,
  SWZ: /[HLMS]\d{3}/,
  TCA: /TKCA 1ZZ/,
  THA: /\d{5}/,
  TJK: /\d{6}/,
  TKM: /\d{6}/,
  TUN: /\d{4}/,
  TUR: /\d{5}/,
  TWN: /\d{3}(\d{2})?/,
  UKR: /\d{5}/,
  URY: /\d{5}/,
  USA: /^\d{5}([ -]\d{4})?$/,
  UZB: /\d{6}/,
  VAT: /00120/,
  VEN: /\d{4}/,
  VIR: /008(([0-4]\d)|(5[01]))([ -]\d{4})?/,
  WLF: /986\d{2}/,
  ZAF: /\d{4}/,
  ZMB: /\d{5}/,
};
