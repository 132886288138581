import { normalize } from 'normalizr';
import { assocPath } from 'ramda';
import assoc from 'ramda/src/assoc';
import filter from 'ramda/src/filter';
import map from 'ramda/src/map';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import path from 'ramda/src/path';
import pipe from 'ramda/src/pipe';

import { types } from './actions';
import { splitFilename } from './components/RequestForQuoteForm/utils';
import { fileSchema } from './schemas';

const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.INIT_DATA:
      return {
        ...state,
        ...payload,
        currentPage: 'form',
      };
    case types.ADD_TEMP_FILES: {
      const normalized = normalize(payload.tempFiles, [fileSchema]);
      return {
        ...state,
        tempFileIds: normalized.result.concat(state.fileIds),
        tempEntities: mergeDeepLeft(state.tempEntities, normalized.entities),
      };
    }
    case types.ADD_SUPPORTING_FILE_IDS: {
      const { ids, fileId, files } = action.payload;
      const nextSupportingFiles = { ...state.entities.supportingFiles };
      ids.forEach((id, index) => {
        nextSupportingFiles[id] = {
          id,
          filename: files[index].name,
          progress: 0,
          error: null,
        };
      });

      return pipe(
        assocPath(['entities', 'supportingFiles'], nextSupportingFiles),
        assocPath(
          ['entities', 'files', fileId, 'supportingFiles'],
          [...state.entities.files[fileId].supportingFiles, ...ids],
        ),
      )(state);
    }
    case types.APPLY_PART_DETAILS_TO_ALL:
      return assocPath(
        ['entities', 'files'],
        map(mergeDeepLeft({ ...payload }), path(['entities', 'files'], state)),
        state,
      );
    case types.APPLY_QUANTITIES_TO_ALL:
      return assocPath(
        ['entities', 'files'],
        map(
          assoc('quantities', payload.value),
          path(['entities', 'files'], state),
        ),
        state,
      );
    case types.FILE_CHANGED:
      return assocPath(
        ['entities', 'files', payload.file.id],
        payload.file,
        state,
      );
    case types.FILE_DELETED:
      return {
        ...state,
        fileIds: state.fileIds.filter((id) => id != payload.fileId),
      };
    case types.FILE_PROGRESS_CHANGED: {
      const { id, progress } = payload;
      return assocPath(
        ['tempEntities', 'files', id, 'progress'],
        progress,
        state,
      );
    }
    case types.FILE_UPLOAD_ERROR:
      return assocPath(
        ['entities', 'files', payload.id, 'error'],
        payload.error.message,
        state,
      );
    case types.FILE_UPLOAD_SUCCESS: {
      const { id: oldId, data } = payload;
      const file = {
        ...path(['entities', 'files', oldId], state),
        ...data,
        supportingFiles: [],
        progress: null,
      };
      const { extension } = splitFilename(file.filename);
      if (['dxf', 'dwg'].includes(extension.toLowerCase())) {
        /* represent DXF/DWG thickness (which is required) as empty string
                   to differentiate from null representation for thickness for other
                   file types */
        file.thickness = '';
      }
      const newFileIds = [...state.fileIds];
      newFileIds.push(data.id);
      return pipe(
        assocPath(['entities', 'files', data.id], file),
        assocPath(['fileIds'], newFileIds),
        assocPath(
          ['tempFileIds'],
          filter((id) => id !== oldId, state.tempFileIds),
        ),
      )(state);
    }
    case types.SET_ERROR:
      return { ...state, error: payload.error };
    case types.SET_IS_UPLOADING:
      return {
        ...state,
        isUploading: payload.isUploading,
      };
    case types.SET_PAGE:
      return { ...state, currentPage: payload.page };
    case types.SET_RFQ_VIEW:
      return { ...state, rfqView: payload.rfqView };
    case types.SUPPORTING_FILE_DELETED: {
      return assocPath(
        ['entities', 'files', [payload.fileId], 'supportingFiles'],
        state.entities.files[payload.fileId].supportingFiles.filter(
          (id) => id !== payload.supportingFileId,
        ),
        state,
      );
    }
    case types.SUPPORTING_FILE_PROGRESS_CHANGED:
      return assocPath(
        ['entities', 'supportingFiles', payload.id, 'progress'],
        payload.progress,
        state,
      );
    case types.SUPPORTING_FILE_UPLOAD_SUCCESS: {
      const { fileId, id: oldId, data } = payload;
      const nextSupportingFiles = state.entities.files[
        fileId
      ].supportingFiles.map((id) => (id === oldId ? data.id : id));
      return pipe(
        assocPath(['entities', 'supportingFiles', data.id], {
          ...data,
          progress: null,
        }),
        assocPath(
          ['entities', 'files', fileId, 'supportingFiles'],
          nextSupportingFiles,
        ),
      )(state);
    }
    default:
      return state;
  }
};

export default reducer;
