import { Field } from 'formik';
import styled from 'styled-components';

import Form from '../ui/Form';
import TextArea from '../ui/TextArea';

const Wrapper = styled.div``;

const Comments = () => {
  return (
    <Wrapper>
      <Field name="description">
        {({ form, field }) => (
          <Form.Field
            error={form.touched[field.name] && form.errors[field.name]}
          >
            <TextArea
              {...field}
              placeholder="Notes, special requests, additional details"
              style={{ resize: 'none' }}
              autosize={{ minRows: 5, maxRows: 100 }}
            />
          </Form.Field>
        )}
      </Field>
    </Wrapper>
  );
};

export default Comments;
