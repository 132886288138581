import { nanoid } from 'nanoid';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  .DateInput_input {
    font-size: 14px;

    ::placeholder {
      color: #747474;
    }
  }

  .DateInput_input__focused {
    border-bottom: 2px solid transparent;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #2240f6;
    border-color: #2240f6;
  }
`;

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.id = `date_picker_${nanoid()}`;
    this.isControlled = props.value !== undefined;
    this.state = {
      value: props.defaultValue || null,
      focused: false,
    };
  }

  handleChange = (value) => {
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
  };

  handleFocus = ({ focused }) => {
    this.setState({ focused });
    const fakeEvent = {
      persist: () => 0,
      target: {
        name: this.props.name,
      },
    };
    if (focused) {
      this.props.onFocus && this.props.onFocus(fakeEvent);
    } else {
      this.props.onBlur && this.props.onBlur(fakeEvent);
    }
  };

  render() {
    const {
      placeholder,
      numberOfMonths,
      format = 'DD/MM/YY',
      value,
      onChange,
    } = this.props;
    const { value: valueFromState, focused } = this.state;
    return (
      <Wrapper>
        <SingleDatePicker
          id={this.id}
          placeholder={placeholder}
          numberOfMonths={numberOfMonths}
          block={true}
          displayFormat={format}
          hideKeyboardShortcutsPanel={true}
          date={this.isControlled ? value : valueFromState}
          onDateChange={this.isControlled ? onChange : this.handleChange}
          focused={focused}
          onFocusChange={this.handleFocus}
        />
      </Wrapper>
    );
  }
}

export default DatePicker;
