export const types = {
  INIT_DATA: 'INIT_DATA',
  ADD_SUPPORTING_FILE_IDS: 'ADD_SUPPORTING_FILE_IDS',
  ADD_TEMP_FILES: 'ADD_TEMP_FILES',
  APPLY_PART_DETAILS_TO_ALL: 'APPLY_PART_DETAILS_TO_ALL',
  APPLY_QUANTITIES_TO_ALL: 'APPLY_QUANTITIES_TO_ALL',
  FILE_CHANGED: 'FILE_CHANGED',
  FILE_DELETED: 'FILE_DELETED',
  FILE_PROGRESS_CHANGED: 'FILE_PROGRESS_CHANGED',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
  SET_ERROR: 'SET_ERROR',
  SET_IS_UPLOADING: 'SET_IS_UPLOADING',
  SET_IS_SUBMITTING: 'SET_IS_SUBMITTING',
  SET_PAGE: 'SET_PAGE',
  SET_RFQ_VIEW: 'SET_RFQ_VIEW',
  SUPPORTING_FILE_DELETED: 'SUPPORTING_FILE_DELETE',
  SUPPORTING_FILE_PROGRESS_CHANGED: 'SUPPORTING_FILE_PROGRESS_CHANGED',
  SUPPORTING_FILE_UPLOAD_ERROR: 'SUPPORTING_FILE_UPLOAD_ERROR',
  SUPPORTING_FILE_UPLOAD_SUCCESS: 'SUPPORTING_FILE_UPLOAD_SUCCESS',
};

export const initData = ({
  materialClasses,
  materialFamilies,
  materialOptions,
  materials,
  processes,
  rfqView,
  settings,
}) => ({
  type: types.INIT_DATA,
  payload: {
    materialClasses,
    materialFamilies,
    materialOptions,
    materials,
    processes,
    rfqView,
    settings,
  },
});

export const addSupportingFileIds = ({ fileId, ids, files }) => ({
  type: types.ADD_SUPPORTING_FILE_IDS,
  payload: { fileId, ids, files },
});

export const addTempFiles = ({ tempFiles }) => ({
  type: types.ADD_TEMP_FILES,
  payload: { tempFiles },
});

export const applyQuantitiesToAll = ({ value }) => ({
  type: types.APPLY_QUANTITIES_TO_ALL,
  payload: { value },
});

/* eslint-disable camelcase */
export const applyPartDetailsToAll = ({
  material,
  process,
  process_finish,
  supplier_material,
}) => ({
  type: types.APPLY_PART_DETAILS_TO_ALL,
  payload: { material, process, process_finish, supplier_material },
});
/* eslint-enable camelcase */

export const fileChanged = ({ file }) => ({
  type: types.FILE_CHANGED,
  payload: { file },
});

export const fileDeleted = ({ fileId }) => ({
  type: types.FILE_DELETED,
  payload: { fileId },
});

export const fileProgressChanged = ({ id, progress }) => ({
  type: types.FILE_PROGRESS_CHANGED,
  payload: { id, progress },
});

export const fileUploadError = ({ id, error }) => ({
  type: types.FILE_UPLOAD_ERROR,
  payload: { id, error },
});

export const fileUploadSuccess = ({ id, data }) => ({
  type: types.FILE_UPLOAD_SUCCESS,
  payload: { id, data },
});

export const setError = (error) => ({
  type: types.SET_ERROR,
  payload: { error },
});

export const setIsSubmitting = (isSubmitting) => ({
  type: types.SET_IS_SUBMITTING,
  payload: { isSubmitting },
});

export const setIsUploading = (isUploading) => ({
  type: types.SET_IS_UPLOADING,
  payload: { isUploading },
});

export const setPage = (page) => ({
  type: types.SET_PAGE,
  payload: { page },
});

export const setRfqView = ({ rfqView }) => ({
  type: types.SET_RFQ_VIEW,
  payload: { rfqView },
});

export const supportingFileDeleted = ({ fileId, supportingFileId }) => ({
  type: types.SUPPORTING_FILE_DELETED,
  payload: { fileId, supportingFileId },
});

export const supportingFileProgressChanged = ({ id, progress }) => ({
  type: types.SUPPORTING_FILE_PROGRESS_CHANGED,
  payload: { id, progress },
});

export const supportingFileUploadError = ({ id, error }) => ({
  type: types.SUPPORTING_FILE_UPLOAD_ERROR,
  payload: { id, error },
});

export const supportingFileUploadSuccess = ({ fileId, id, data }) => ({
  type: types.SUPPORTING_FILE_UPLOAD_SUCCESS,
  payload: { fileId, id, data },
});
