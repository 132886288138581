import { StoreContext } from 'Store';
import { useContext } from 'react';
import styled from 'styled-components';

import Form from '../ui/Form';
import { CloseIcon } from '../ui/Icons';
import Progress from './Progress';
import UploadError from './UploadError';
import { splitFilename } from './utils';

const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  color: #aaa;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;

  > svg {
    display: block;
  }
`;

const Details = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  padding: 10px 13px 10px 13px;
  flex-wrap: wrap;
`;

const DetailsWrapper = styled(Form.Field)`
  flex-grow: 1;
  min-width: 0;
`;

const FileInfoWrapper = styled.div`
  display: flex;
`;

const Filename = styled.span`
  flex: 1 0 50%;
  display: flex;
  color: #4a4a4a;
  padding-right: 15px;
`;

const Name = styled.span`
  word-break: break-all;
`;

const ProgressContainer = styled.div`
  flex: 0 1 50%;
`;

const StatusAndActions = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  > * {
    margin-right: 5px;
  }
`;

const Wrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const SupportingFile = ({ id, onDelete, parentId }) => {
  const { getSupportingFile } = useContext(StoreContext);
  const value = getSupportingFile(id);
  if (value.error) {
    return (
      <FileInfoWrapper>
        <UploadError file={value} onDelete={() => onDelete(parentId, value)} />
      </FileInfoWrapper>
    );
  }
  const { name, extension } = splitFilename(value.filename);
  const isInProgress = value.progress !== null;
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      onDelete(parentId, value);
    }
  };
  return (
    <Wrapper>
      <FileInfoWrapper>
        <DetailsWrapper>
          <Details>
            <Filename>
              <Name>
                {name}.{extension}
              </Name>
            </Filename>
            {isInProgress && (
              <ProgressContainer>
                <Progress value={value.progress} />
              </ProgressContainer>
            )}
            <StatusAndActions>
              <DeleteButton type="button" onClick={handleDelete}>
                <CloseIcon />
              </DeleteButton>
            </StatusAndActions>
          </Details>
        </DetailsWrapper>
      </FileInfoWrapper>
    </Wrapper>
  );
};

export default SupportingFile;
