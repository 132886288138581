import { nanoid } from 'nanoid';
import { denormalize } from 'normalizr';
import queryString from 'query-string';
import { sortBy } from 'ramda';
import React, { useEffect, useReducer } from 'react';

import {
  addSupportingFileIds,
  addTempFiles,
  applyPartDetailsToAll,
  applyQuantitiesToAll,
  fileChanged,
  fileDeleted,
  fileProgressChanged,
  fileUploadError,
  fileUploadSuccess,
  initData,
  setError,
  setIsSubmitting,
  setIsUploading,
  setPage,
  setRfqView,
  supportingFileDeleted,
  supportingFileProgressChanged,
  supportingFileUploadError,
  supportingFileUploadSuccess,
} from './actions';
import api from './api';
import {
  DEFAULT_UNITS,
  OTHER_MATERIAL,
} from './components/RequestForQuoteForm/constants';
import { inToMm, splitFilename } from './components/RequestForQuoteForm/utils';
import reducer from './reducer';
import { fileSchema } from './schemas';

const getAllMaterialsForSupplierMaterials = (
  allMaterials,
  supplierMaterials,
) => {
  const materialIds = [
    ...new Set(
      supplierMaterials.map((supplierMaterial) => supplierMaterial.material_id),
    ),
  ];
  return allMaterials.filter((material) => materialIds.includes(material.id));
};

const getAllFamiliesForMaterials = (allFamilies, materials) => {
  const familyIds = [
    ...new Set(materials.map((material) => material.material_family_id)),
  ];
  return allFamilies.filter((family) => familyIds.includes(family.id));
};

const getAllClassesForFamilies = (allClasses, families) => {
  const classIds = [
    ...new Set(families.map((family) => family.material_class_id)),
  ];
  return allClasses.filter((matClass) => classIds.includes(matClass.id));
};

const constructMaterialCascaderOptions = (
  useClasses,
  useFamilies,
  useMaterials,
  useSupplierMaterials,
  shouldShowOther,
) => {
  const sortByLabel = sortBy((x) => x.label.toLowerCase());
  const toOption = (isLeaf) => (x) => ({ label: x.name, value: x.id, isLeaf });
  // first level - material classes
  const result = useClasses.map(toOption(false));
  for (const classOption of result) {
    const families = useFamilies.filter(
      (family) => family.material_class_id == classOption.value,
    );
    // second level - material families
    classOption.children = families.map(toOption(false));
    for (const familyOption of classOption.children) {
      const materials = useMaterials.filter(
        (material) => material.material_family_id == familyOption.value,
      );
      // third level - materials
      familyOption.children = materials.map(toOption(true));
      // material custom names
      for (const materialOption of familyOption.children) {
        const supplierMaterials = useSupplierMaterials.filter(
          (supplierMaterial) =>
            supplierMaterial.material_id == materialOption.value,
        );
        // fourth level - optional for none, one, or multiple supplier materials
        if (supplierMaterials.length > 1) {
          const children = [];
          for (const supplierMaterial of supplierMaterials) {
            children.push({
              label: supplierMaterial.custom_name || materialOption.label,
              value: supplierMaterial.id,
              isLeaf: true,
            });
          }
          materialOption.children = sortByLabel(children);
        } else if (supplierMaterials.length === 1) {
          const firstSupplierMaterial = (supplierMaterials || [])[0];
          const { customName } = firstSupplierMaterial || {};
          materialOption.label = customName || materialOption.label;
        }
      }
      familyOption.children = sortByLabel(familyOption.children);
    }
    // need to filter out families with no material children
    classOption.children = sortByLabel(
      classOption.children.filter((fam) => fam.children.length),
    );
  }
  // filter out classes with no families
  // filter so additive materials are at the bottom of the cascader, otherwise alphabetical
  // TODO: remove this once existing supplier base utilizes the material selector customization
  const trueResult = result
    .filter(
      (classOption) => classOption.children && classOption.children.length,
    )
    .sort((a, b) => {
      if (a.label === 'Additive') {
        return 1;
      } else if (b.label === 'Additive') {
        return -1;
      } else {
        return a.label - b.label;
      }
    });

  if (shouldShowOther) {
    // now add special Other See Notes material option
    trueResult.push({
      label: 'Other (See Notes)',
      value: OTHER_MATERIAL,
      isLeaf: false,
      children: [
        {
          label: 'Other (See Notes)',
          value: OTHER_MATERIAL,
          isLeaf: false,
          children: [
            {
              label: 'Other (See Notes)',
              value: OTHER_MATERIAL,
              isLeaf: true,
            },
          ],
        },
      ],
    });
  }

  return trueResult;
};

export const getMaterialOptionsForCascaderForSupplierMaterials = (
  allClasses,
  allFamilies,
  allMaterials,
  supplierMaterials,
  shouldShowOther = false,
) => {
  const useMaterials = getAllMaterialsForSupplierMaterials(
    allMaterials,
    supplierMaterials,
  );
  const useFamilies = getAllFamiliesForMaterials(allFamilies, useMaterials);
  const useClasses = getAllClassesForFamilies(allClasses, useFamilies);
  return constructMaterialCascaderOptions(
    useClasses,
    useFamilies,
    useMaterials,
    supplierMaterials,
    shouldShowOther,
  );
};

/**
 * Create 3-level tree (material classes -> material families -> materials)
 * compatible with antd's Cascader component
 */
export const getMaterialOptionsForCascader = (
  selectorSettings,
  allClasses,
  allFamilies,
  allMaterials,
  allSupplierMaterials,
) => {
  const {
    excluded_material_class_ids: excludedMaterialClassIds,
    excluded_material_family_ids: excludedMaterialFamilyIds,
    excluded_material_ids: excludedMaterialIds,
    only_include_supplier_materials: onlyIncludeSupplierMaterials,
  } = selectorSettings;

  if (onlyIncludeSupplierMaterials) {
    return getMaterialOptionsForCascaderForSupplierMaterials(
      allClasses,
      allFamilies,
      allMaterials,
      allSupplierMaterials,
      true,
    );
  } else {
    const useClasses = allClasses.filter(
      (matClass) => !excludedMaterialClassIds.includes(matClass.id),
    );
    const useClassIds = useClasses.map((matClass) => matClass.id);
    const useFamilies = allFamilies.filter(
      (family) =>
        !excludedMaterialFamilyIds.includes(family.id) &&
        useClassIds.includes(family.material_class_id),
    );
    const useFamilyIds = useFamilies.map((family) => family.id);
    const useMaterials = allMaterials.filter(
      (material) =>
        !excludedMaterialIds.includes(material.id) &&
        useFamilyIds.includes(material.material_family_id),
    );

    return constructMaterialCascaderOptions(
      useClasses,
      useFamilies,
      useMaterials,
      allSupplierMaterials,
      true,
    );
  }
};

const DEFAULT_MAX_MB = 50;

const validateFile = (file, maxMB) => {
  const maxSize = maxMB * 1024 * 1024;
  if (file.size > maxSize) {
    return new Error(`Can't upload files bigger than ${maxMB} MB`);
  }
  return null;
};

export const StoreContext = React.createContext({});

const Store = ({ children }) => {
  // const [isApiHealthy, setIsApiHealthy] = useState();
  const queryParams = queryString.parse(window.location.search);
  const initialState = {
    currentPage: 'loading',
    isUploading: false,
    isSubmitting: false,
    error: null,
    process: null,
    supplierId: queryParams.supplier_id || null,
    redirectUrl: queryParams.redirect_url || null,
    marketing_source: queryParams.marketing_source || null,
    materialOptions: [],
    materialClasses: [],
    materialFamilies: [],
    materials: [],
    settings: {},
    rfqView: {},
    fileIds: [],
    tempFileIds: [],
    tempEntities: {},
    entities: {},
  };

  const [globalState, dispatch] = useReducer(reducer, initialState);

  const {
    entities,
    fileIds,
    isUploading,
    redirectUrl,
    rfqView,
    settings,
    supplierId,
    tempEntities,
    tempFileIds,
  } = globalState;

  const getFiles = () => {
    return denormalize(fileIds, [fileSchema], entities);
  };

  const getTempFiles = () => {
    return denormalize(tempFileIds, [fileSchema], tempEntities);
  };

  const getFile = (fileId) => {
    const entity = entities.files[fileId];
    return denormalize(entity, [fileSchema], entities);
  };

  const getTempFile = (fileId) => {
    const entity = tempEntities.files[fileId];
    return denormalize(entity, [fileSchema], tempEntities);
  };

  const getSupportingFile = (fileId) => entities['supportingFiles'][fileId];

  // const checkHealth = async () => {
  //   try {
  //     const { mode } = await api.fetchApiHealth();
  //     setIsApiHealthy(mode === 'healthy');
  //   } catch (e) {
  //     setIsApiHealthy(false);
  //   }
  // };

  // useEffect(() => {
  //   checkHealth();
  //   const interval = setInterval(() => {
  //     checkHealth();
  //   }, 30000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const init = async () => {
      let processes = [];
      let materialsInfo = {};
      let settings = {};
      let rfqView = {};
      try {
        processes = await api.fetchProcesses(supplierId);
        materialsInfo = await api.fetchMaterials(supplierId);
        settings = await api.fetchSettings(supplierId);
        rfqView = await api.postRFQView(supplierId);
      } catch (e) {
        // catch any fetching errors
      }

      const {
        material_classes: materialClasses,
        material_families: materialFamilies,
        materials,
        supplier_materials: supplierMaterials,
        material_selector_settings: materialSelectorSettings,
      } = materialsInfo;

      const materialOptions = getMaterialOptionsForCascader(
        materialSelectorSettings,
        materialClasses,
        materialFamilies,
        materials,
        supplierMaterials,
      );
      dispatch(
        initData({
          materialClasses,
          materialFamilies,
          materialOptions,
          materials,
          processes,
          settings,
          rfqView,
        }),
      );
    };

    init();
  }, [supplierId]);

  // Update RFQ View only when the previous state was false
  const updateRFQView = async (status) => {
    const currStatus = rfqView[status.toLowerCase()];
    if (!currStatus) {
      const data = {};
      data[status.toLowerCase()] = true;
      dispatch(
        setRfqView({ rfqView: await api.updateRFQView(rfqView.uuid, data) }),
      );
    }
  };

  const uploadFile = async ({
    files,
    parentFileId,
    tempIds,
    onProgressChange,
    onSuccess,
    onError,
    uploadOptions,
  }) => {
    dispatch(setIsUploading(true));
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const id = tempIds[i];
      const validationError = validateFile(
        file,
        settings.file_upload_size_limit || DEFAULT_MAX_MB,
      );
      if (validationError) {
        onError({ id, error: validationError });
      } else {
        try {
          const data = await api.upload(
            file,
            parentFileId,
            (progress) => onProgressChange({ id, progress }),
            uploadOptions,
          );
          onSuccess({ id, data });
        } catch (error) {
          onError({ id, error });
        }
      }
    }
    dispatch(setIsUploading(false));
  };

  const deleteFile = async (id) => {
    return await api.deleteFile(id);
  };

  const updateFile = async (id, data) => {
    return await api.updateFile(id, data);
  };

  const submit = async (values) => {
    try {
      if (isUploading) {
        return;
      }

      values.rfq_view_uuid = rfqView.uuid;
      if (values.export_controlled === undefined)
        values.export_controlled = false;

      if (globalState.marketing_source)
        values.marketing_source = globalState.marketing_source;

      dispatch(setIsSubmitting(true));
      dispatch(setError(null));

      const data = await api.sendRequest(supplierId, values);

      if (data && data.error) {
        throw new Error(data.message);
      }

      window.parent.postMessage(
        {
          type: 'rfq_submit_success',
        },
        '*',
      );

      if (redirectUrl) {
        window.top.location.href = redirectUrl;
      } else {
        dispatch(setPage('success'));
        window.scrollTo(0, 0);
      }
    } catch (e) {
      dispatch(setError(e.message));
    } finally {
      dispatch(setIsSubmitting(false));
    }
  };

  const handleProgressChange = ({ id, progress }) =>
    dispatch(fileProgressChanged({ id, progress }));

  const handleUploadSuccess = ({ id, data }) =>
    dispatch(fileUploadSuccess({ id, data }));

  const handleUploadError = ({ id, error }) =>
    dispatch(fileUploadError({ id, error }));

  const addTemporaryFileObjects = (ids, files) => {
    const tempFiles = files.map((file, index) => {
      const { extension } = splitFilename(file.name);
      return {
        id: ids[index],
        filename: file.name,
        units: extension.toLowerCase() === 'stl' ? 'mm' : null,
        thickness: ['dxf', 'dwg'].includes(extension.toLowerCase()) ? '' : null,
        quantities: '',
        progress: 0,
        error: null,
        supportingFiles: [],
      };
    });
    dispatch(addTempFiles({ tempFiles }));
  };

  const handleUpload = async (files) => {
    const options = {};
    files.forEach((file) => {
      const { extension } = splitFilename(file.name);
      if (extension.toLowerCase() === 'stl') {
        options.units = DEFAULT_UNITS;
      }
    });
    const tempIds = files.map(nanoid);
    addTemporaryFileObjects(tempIds, files);
    await uploadFile({
      files,
      tempIds,
      onProgressChange: handleProgressChange,
      onSuccess: handleUploadSuccess,
      onError: handleUploadError,
      uploadOptions: options,
    });
  };

  const handleSupportingFileUpload = async (fileId, files) => {
    const handleProgressChange = ({ id, progress }) =>
      dispatch(supportingFileProgressChanged({ id, progress }));
    const handleSuccess = ({ id, data }) =>
      dispatch(supportingFileUploadSuccess({ fileId, id, data }));
    const handleError = ({ id, error }) =>
      dispatch(supportingFileUploadError({ id, error }));
    const ids = files.map(nanoid);
    dispatch(addSupportingFileIds({ fileId, ids, files }));
    await uploadFile({
      files,
      tempIds: ids,
      parentFileId: fileId,
      onProgressChange: handleProgressChange,
      onSuccess: handleSuccess,
      onError: handleError,
    });
  };

  const handleFileChange = (file) => dispatch(fileChanged({ file }));

  const handleFileDelete = async (file) => {
    if (!file.progress && !file.error) {
      await api.deleteFile(file.id);
    }
    dispatch(fileDeleted({ fileId: file.id }));
  };

  const handleUpdateUnitsThickness = async (file) => {
    const { id, thickness, units } = file;
    handleFileChange(file);
    if (!file.progress && !file.error) {
      // convert thickness input values to MM as the thickness will be interpretted
      // against file units, which will always be converted internally to be MM
      await updateFile(id, { thickness: inToMm(thickness), units });
    }
  };
  //
  const handleSupportingFileDelete = async (fileId, supportingFile) => {
    if (!supportingFile.progress && !supportingFile.error) {
      await deleteFile(supportingFile.id);
    }
    dispatch(
      supportingFileDeleted({
        fileId: fileId,
        supportingFileId: supportingFile.id,
      }),
    );
  };

  const handleApplyQuantitiesToAll = (value) =>
    dispatch(applyQuantitiesToAll({ value }));

  /* eslint-disable camelcase */
  const handleApplyPartDetailsToAll = ({
    material,
    process,
    process_finish,
    supplier_material,
  }) => {
    dispatch(
      applyPartDetailsToAll({
        material,
        process,
        process_finish,
        supplier_material,
      }),
    );
  };
  /* eslint-enable camelcase */

  return (
    <StoreContext.Provider
      value={{
        globalState: {
          ...globalState,
          files: getFiles(),
          tempFiles: getTempFiles(),
        },
        getFile,
        getTempFile,
        getSupportingFile,
        handleApplyPartDetailsToAll,
        handleApplyQuantitiesToAll,
        handleFileDelete,
        handleFileChange,
        handleUpdateUnitsThickness,
        handleUpload,
        handleSupportingFileDelete,
        handleSupportingFileUpload,
        submit,
        uploadFile,
        updateRFQView,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default Store;
