/* eslint-disable camelcase */
import { StoreContext } from 'Store';
import pick from 'ramda/src/pick';
import { useContext, useEffect, useRef } from 'react';
import ReactDropzone from 'react-dropzone';
import styled from 'styled-components';

import ErrorMessage from '../common/ErrorMessage';
import Form from '../ui/Form';
import Select from '../ui/Select';
import Input from '../ui/TextInput';
import Toggle from '../ui/Toggle';
import CascaderMaterialSelector from './CascaderMaterialSelector';
import DXFInputs from './DXFInputs';
import ProcessMaterialSelector from './ProcessMaterialSelector';
import Progress from './Progress';
import UploadError from './UploadError';
import { parseQuantities, splitFilename } from './utils';

const buttonStyles = `
  color: #2240f6;
  text-transform: uppercase;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

const ApplyToAllButton = styled.button`
  font-size: inherit;
  font-weight: 600;
  ${buttonStyles};
`;

const AddSupportingFileButton = styled.button`
  display: block;
  font-size: 11px;
  letter-spacing: 0.4px;
  margin-top: 4px;
  ${buttonStyles};
`;

const AddSupportingFileDropzone = styled(ReactDropzone)`
  display: block;
  border: none;
  padding: 0;
  input {
    width: 0;
  }
`;

const Details = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  padding: 10px 13px 10px 13px;
  flex-wrap: wrap;
`;

const DetailsWrapper = styled(Form.Field)`
  flex-grow: 1;
  min-width: 0;
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
`;

const FileInfoWrapper = styled.div`
  display: flex;
`;

const Filename = styled.span`
  flex: 1 0 50%;
  display: flex;
  color: #4a4a4a;
  padding-right: 15px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const OptionsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const PartDetailsHeader = styled.div`
  color: #828282;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 15px;
  padding-top: 5px;
  text-transform: uppercase;
`;

const ProcessSelect = styled(Select)`
  font-size: 14px;
  margin-right: 10px;
  width: 100%;
`;

const ProgressContainer = styled.div`
  flex: 0 1 50%;
`;

const Name = styled.span`
  word-break: break-all;
`;

const FinishSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
`;

const QuantityWrapper = styled.div`
  flex: 0 0 120px;
  margin-left: 10px;
  input {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }
`;

const StatusAndActions = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  > * {
    margin-right: 5px;
  }
`;

const StyledToggle = styled(Toggle)`
  margin-right: 10px;
`;

const StyledMaterialSelector = styled(CascaderMaterialSelector)`
  margin-right: 10px;
`;

const Wrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const File = ({
  index,
  isTemp,
  value,
  onBlur,
  onDelete,
  thicknessError,
  thicknessTouched,
  setThicknessTouched,
}) => {
  const {
    globalState,
    handleSupportingFileUpload: onUploadSupportingFile,
    handleApplyQuantitiesToAll: onApplyQuantitiesToAll,
    handleUpdateUnitsThickness: onUnitsThicknessChange,
    handleApplyPartDetailsToAll: onApplyPartDetailsToAll,
    handleFileChange: onChange,
  } = useContext(StoreContext);

  const { processes, materialOptions, settings } = globalState;
  const {
    default_process_id,
    show_process_selector,
    show_material_selector,
    show_finish_selector,
  } = settings.smart_rfq_settings;
  const initRef = useRef(false);

  useEffect(() => {
    if (!show_process_selector && !initRef.current) {
      initRef.current = true;
      onChange({
        ...value,
        process: default_process_id,
        material: null,
        supplier_material: null,
        process_finish: [],
      });
    }
  }, [default_process_id, onChange, show_process_selector, value]);

  if (value.error) {
    return <UploadError file={value} onDelete={onDelete} />;
  }

  const { name, extension } = splitFilename(value.filename);
  const isInProgress = value.progress !== null;
  const shouldEnterThickness =
    extension && ['dxf', 'dwg'].includes(extension.toLowerCase());

  const getDefaultOption = (label) => ({
    value: null,
    label: <span style={{ color: '#747474' }}>{label}</span>,
  });

  const getFinishOptions = (processId) => {
    const options = [];
    if (!processId) return options;
    const process = processes.filter((x) => x.id === processId)[0];
    return options.concat(
      process.finishes
        .map((finish) => ({
          value: finish.id,
          label: finish.name,
        }))
        .sort((x, y) => x.label.localeCompare(y.label)),
    );
  };

  return (
    <Wrapper>
      <FileInfoWrapper>
        <DetailsWrapper label="File Name">
          <Details>
            <Filename>
              {extension && extension.toLowerCase() === 'stl' && (
                <StyledToggle
                  checkedColor="#2240f6"
                  uncheckedColor="#4ca64c"
                  checkedChildren="mm"
                  uncheckedChildren="inch"
                  checked={value.units === 'mm'}
                  onChange={(e) =>
                    onUnitsThicknessChange({
                      ...value,
                      units: e.target.checked ? 'mm' : 'in',
                    })
                  }
                />
              )}
              <Name>
                {name}.{extension}
              </Name>
            </Filename>
            {isInProgress && (
              <ProgressContainer>
                <Progress value={value.progress} />
              </ProgressContainer>
            )}
            <StatusAndActions>
              {!isInProgress && (
                <AddSupportingFileDropzone
                  onDrop={(files) => onUploadSupportingFile(value.id, files)}
                >
                  <AddSupportingFileButton type="button">
                    Add supporting file
                  </AddSupportingFileButton>
                </AddSupportingFileDropzone>
              )}
            </StatusAndActions>
          </Details>
        </DetailsWrapper>
        <QuantityWrapper>
          <Form.Field
            label={
              <div>
                Qty -{' '}
                <ApplyToAllButton
                  type="button"
                  onClick={() => onApplyQuantitiesToAll(value.quantities)}
                >
                  Apply to all
                </ApplyToAllButton>
              </div>
            }
          >
            <Input
              placeholder="Qty (1,5,10,25)"
              value={value.quantities}
              onChange={(e) =>
                onChange({ ...value, quantities: e.target.value })
              }
              onBlur={(e) => {
                onChange({
                  ...value,
                  quantities: parseQuantities(value.quantities).join(','),
                });
                onBlur && onBlur(e);
              }}
            />
          </Form.Field>
        </QuantityWrapper>
      </FileInfoWrapper>
      <Options>
        {(show_process_selector ||
          show_material_selector ||
          show_finish_selector) && (
          <PartDetailsHeader>
            Part Details -{' '}
            <ApplyToAllButton
              type="button"
              onClick={() =>
                onApplyPartDetailsToAll(
                  pick(
                    [
                      'material',
                      'process',
                      'process_finish',
                      'supplier_material',
                    ],
                    value,
                  ),
                )
              }
            >
              Apply To All
            </ApplyToAllButton>
          </PartDetailsHeader>
        )}
        <OptionsRow>
          {show_process_selector && (
            <ProcessSelect
              disabled={isTemp}
              value={value.process}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              placeholder="Process"
              clearable={false}
              options={[getDefaultOption('Process')].concat(
                processes.map((process) => ({
                  value: process.id,
                  label: process.external_name || process.name,
                })),
              )}
              onChange={(option) => {
                onChange({
                  ...value,
                  process: option,
                  material: null,
                  supplier_material: null,
                  process_finish: [],
                });
              }}
            />
          )}
          {show_material_selector &&
            (() => {
              const process = processes.filter(
                (x) => x.id === value.process,
              )[0];
              if (process && process.use_global_materials) {
                return (
                  <StyledMaterialSelector
                    disabled={isTemp}
                    materialId={value.material}
                    materialOptions={materialOptions}
                    placeholder="Material"
                    onChange={({ materialId, supplierMaterialId }) => {
                      onChange({
                        ...value,
                        material: materialId,
                        supplier_material: supplierMaterialId,
                      });
                    }}
                    supplierMaterialId={value.supplier_material}
                  />
                );
              } else {
                return (
                  <ProcessMaterialSelector
                    disabled={isTemp}
                    onChange={onChange}
                    process={process}
                    values={value}
                  />
                );
              }
            })()}
          {show_finish_selector && (
            <FinishSelect
              disabled={isTemp}
              value={value.process_finish}
              placeholder="Finish"
              clearable={false}
              options={getFinishOptions(value.process)}
              isMulti={true}
              onChange={(options) =>
                onChange({ ...value, process_finish: options ? options : [] })
              }
            />
          )}
        </OptionsRow>
      </Options>
      {shouldEnterThickness && (
        <DXFInputs
          value={value}
          onUnitsThicknessChange={onUnitsThicknessChange}
          thicknessError={thicknessError}
          thicknessTouched={thicknessTouched}
          setThicknessTouched={setThicknessTouched}
        />
      )}
      <Error>
        <ErrorMessage name={`options[${index}]quantities`} />
      </Error>
    </Wrapper>
  );
};

export default File;
