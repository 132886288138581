export const splitFilename = (filename) => {
  const index = filename.lastIndexOf('.');
  if (index === -1) {
    return { name: filename, extension: '' };
  } else {
    return {
      name: filename.slice(0, index),
      extension: filename.slice(index + 1),
    };
  }
};

export const parseQuantities = (x) => {
  let result = [];
  if (x) {
    result = x
      .replace(' ', '')
      .split(',')
      .map((x) => parseInt(x, 10))
      .filter((x) => !isNaN(x));
  }
  return result.length === 0 ? [] : result;
};

export const mmToIn = (x) =>
  isNaN(parseFloat(x)) ? null : parseFloat(x) / 25.4;

export const inToMm = (x) =>
  isNaN(parseFloat(x)) ? null : parseFloat(x) * 25.4;
