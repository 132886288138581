export const API_URL = process.env.REACT_APP_API_URL;
const STATIC_URL = process.env.REACT_APP_STATIC_API_URL;
export default {
  fetchApiHealth: async () => {
    const response = await fetch(`${STATIC_URL}/health.json`);
    return response.json();
  },

  fetchProcesses: async (supplierId) => {
    const response = await fetch(
      `${API_URL}/suppliers/${supplierId}/public_processes`,
    );
    return await response.json();
  },

  fetchMaterials: async (supplierId) => {
    const response = await fetch(
      `${API_URL}/suppliers/${supplierId}/public_materials`,
    );
    return await response.json();
  },

  fetchSettings: async (supplierId) => {
    const response = await fetch(
      `${API_URL}/suppliers/${supplierId}/public_settings`,
    );
    return await response.json();
  },

  postRFQView: async (supplierId, data) => {
    const response = await fetch(`${API_URL}/rfq/${supplierId}/create_view`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
    });
    return await response.json();
  },

  updateRFQView: async (uuid, data) => {
    return await fetch(`${API_URL}/rfq/update_view/${uuid}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
    }).then((response) => {
      return response.json();
    });
  },

  sendRequest: async (supplierId, data) => {
    const response = await fetch(`${API_URL}/rfq/${supplierId}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
    });
    return await response.json();
  },

  upload: (file, parentFileId, onProgress, uploadOptions = {}) => {
    return new Promise((res, rej) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener(
        'progress',
        function (e) {
          if (e.lengthComputable) {
            onProgress && onProgress(e.loaded / e.total);
          }
        },
        false,
      );

      xhr.addEventListener('load', function () {
        try {
          res(JSON.parse(this.responseText));
        } catch (e) {
          rej(new Error('Unknown error.'));
        }
      });

      xhr.addEventListener('error', () => {
        rej(new Error('Upload error.'));
      });

      xhr.open('POST', `${API_URL}/rfq/file_upload`);

      const formData = new FormData();
      formData.append('file', file);
      if (parentFileId) {
        formData.append('parent_file_id', parentFileId);
      }
      Object.entries(uploadOptions).forEach(([key, value]) => {
        formData.append(key, value);
      });
      xhr.send(formData);
    });
  },

  updateFile: async (id, data) => {
    return await fetch(`${API_URL}/rfq/file/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
    }).then((response) => {
      return response.json();
    });
  },

  deleteFile: async (id) => {
    await fetch(`${API_URL}/rfq/file/${id}`, {
      method: 'DELETE',
    });
    return null;
  },
};
