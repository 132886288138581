import t from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Inner = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotation} 1s infinite linear;
`;

const Wrapper = styled.span`
  ${(props) =>
    props.size &&
    `
    font-size: ${props.size}px;
  `} ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
`;

class Spinner extends React.Component {
  static propTypes = {
    size: t.string,
    color: t.string,
  };

  render() {
    return (
      <Wrapper {...this.props}>
        <Inner />
      </Wrapper>
    );
  }
}

export default styled(Spinner)``;
