import Input from 'antd/lib/input';
import 'antd/lib/input/style/css';
import styled from 'styled-components';

const StyledTextArea = styled(Input.TextArea)`
  && {
    display: block;
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 2px;
    background-color: #ffffff;
    outline: none;
    padding: 0.92em 0.85em;
    font-family: inherit;
    font-size: 14px;
    transition: none;
  }

  &[disabled] {
    background-color: #f4f4f4;
  }

  ::placeholder {
    color: #747474 !important;
  }

  :hover:not([disabled]) {
    border-color: #aaaaaa;
  }

  :focus:not([disabled]) {
    border-color: #828282;
    box-shadow: none;
  }
`;

export default StyledTextArea;
